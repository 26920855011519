export const CategoryFragment = `
  fragment CategoryFragment on Category {
    id
    name
    display {
      ...UploadFileFragment
    }
    products {
      ...ProductFragment
    }
    featuredProducts {
      featuredProduct1 {
        id
      }
      featuredProduct2 {
        id
      }
      featuredProduct3 {
        id
      }
    }
  }
`;
