import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(
  (theme) => {
    const imageWidth = 468;
    const imageHeight = 746;

    const padWidth = 517;
    const padHeight = 120;

    const totalWidth = padWidth;
    const totalHeight = imageHeight + padHeight;

    const imageLeftSpacing = 23;
    const imageRightSpacing = 26;

    const imageLeftPercentage = (imageLeftSpacing / totalWidth) * 100;
    const imageRightPercentage = (imageRightSpacing / totalWidth) * 100;

    const slidesWidth = 418;
    const slidesHeight = 110;
    const slidesBottom = 110;

    const thumbnailWidth = 128;
    const thumbnailHeight = slidesHeight;
    const thumbanilBorder = 5;
    const thumbnailFontSize = 24;

    const slidesWidthPercentage = (slidesWidth / totalWidth) * 100;
    const slidesHeightPercentage = (thumbnailHeight / totalHeight) * 100;
    const slidesBottomPercentage = (slidesBottom / totalHeight) * 100;

    const thumbnailWidthPercentage = (thumbnailWidth / totalWidth) * 100;
    const thumbnailHeightPercentage = (thumbnailHeight / totalHeight) * 100;
    const thumbanilBorderPercentage = (thumbanilBorder / thumbnailWidth) * 100;

    const navigationWidthPercentage = (20 / totalWidth) * 100;
    const navigationHeightPercentage = (23.3 / totalHeight) * 100;

    return {
      root: {
        '--navigation-color': theme.palette.secondary.light,
        '--navigation-color--hover': theme.palette.secondary.main,

        opacity: 0,
        height: 0,
        // overflow: 'hidden',
        position: 'relative',
        width: '100%',
        paddingTop: `var( --aspect-ratio, ${(totalHeight / totalWidth) * 100}% )`,
        transition: theme.transitions.create(['transform']),
      },
      inactive: {
        marginTop: '10%',
        transform: 'scale(.8)',
        filter: 'brightness(.7)',
      },
      content: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      },
      background: {
        display: 'flex',
        flexDirection: 'column',
      },
      image: {
        width: `${(imageWidth / totalWidth) * 100}%`,
        height: `${(imageHeight / totalHeight) * 100}%`,
        marginLeft: `${imageLeftPercentage}%`,
        marginRight: `${imageRightPercentage}%`,
        objectFit: 'cover',
      },
      padImage: {
        width: '100%',
      },
      slides: {
        width: `${slidesWidthPercentage}%`,
        position: 'absolute',
        bottom: `${slidesBottomPercentage}%`,
        left: 0,
        right: 0,
        display: 'flex',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: `${thumbanilBorderPercentage}%`,
        boxSizing: 'content-box',
        bottom: '-2%',
      },
      slide: {
        position: 'relative',
        marginLeft: '2.1%',
        marginRight: '2.1%',
        transition: theme.transitions.create(['filter']),
        cursor: 'pointer',
        width: ({ size }) => {
          return `${((size?.width / totalWidth) * totalWidth * thumbnailWidthPercentage) / 100}px`;
        },

        '&:hover': {
          filter: 'brightness(1.4)',
        },

        '&:first-child': {
          marginLeft: 0,
        },

        '&:last-child': {
          marginRight: 0,
        },
      },
      slidesThumbnails: {
        '& > div': {
          height: `${slidesHeightPercentage}%`,
        },
      },
      slideImage: {
        objectFit: 'cover',
        height: ({ size }) => {
          return `${((size?.width / totalWidth) * totalHeight * thumbnailHeightPercentage) / 100}px`;
        },
        width: ({ size }) => {
          return `${((size?.width / totalWidth) * totalWidth * thumbnailWidthPercentage) / 100}px`;
        },
        padding: `${thumbanilBorderPercentage}%`,
        boxSizing: 'border-box',
        backgroundColor: 'white',
        boxShadow: '1px 1px 5px 1px #000000',
      },
      slideTitle: {
        color: 'black',
        width: '100%',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textTransform: 'uppercase',
        paddingTop: '23%',
        objectFit: 'contain',
        fontFamily: theme.typography.fontFamilies.dinot,
        fontSize: ({ size }) => {
          return `${(size?.width / totalWidth) * thumbnailFontSize}px`;
        },
      },
      slideTitleImage: {
        paddingTop: '3%',
      },
      navigation: {
        width: `${navigationWidthPercentage}%`,
        position: 'absolute',
        fill: 'var(--navigation-color)',
        cursor: 'pointer',
        bottom: `${
          slidesBottomPercentage +
          thumbnailHeightPercentage / 2 -
          navigationHeightPercentage / 2 +
          thumbanilBorderPercentage / 2
        }%`,
        padding: 0,
        transition: theme.transitions.create(['border-color']),
        '&:hover': {
          fill: 'var(--navigation-color--hover)',
        },
      },
      navigationPrev: {
        left: `${navigationWidthPercentage * 0.5}%`,
      },
      navigationNext: {
        right: `${navigationWidthPercentage * 0.7}%`,
        transform: 'rotate(180deg)',
      },
    };
  },
  { name: 'CategoryCard' },
);
