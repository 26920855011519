import { ApolloClient, InMemoryCache } from '@apollo/client';

export const createApolloClient = ({ uri = `${process.env.REACT_APP_ENDPOINT}/graphql`, cache }) => {
  return new ApolloClient({
    uri,
    cache: cache ? new InMemoryCache() : undefined,
    defaultOptions: {
      query: {
        fetchPolicy: 'cache-only',
        errorPolicy: 'all',
      },
    },
  });
};
