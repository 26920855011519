import { useRef, useLayoutEffect, useState } from 'react';
import clsx from 'clsx';

import { Arrow } from '../Arrow';
import { useStyles } from './ModalContainer.style';
import { useTheme } from '@material-ui/core';

// import Swiper core and required modules
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Keyboard } from 'swiper';
import 'swiper/swiper.min.css';
// import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Keyboard]);

export function ModalContainer({
  className,
  data,
  swiperProps = {},
  Slide,
  onSlideChange,
  showBackground = true,
  blur = false,
  onClose,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [swiperReady, setSwiperReady] = useState();
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className={clsx(classes.root, className, { [classes.blur]: blur })}>
      <div className={clsx(classes.background, { [classes.showBackground]: showBackground })} onClick={onClose} />
      <Swiper
        className={classes.swiper}
        navigation={{
          prevEl: `.${classes.navigationPrev}`,
          nextEl: `.${classes.navigationNext}`,
        }}
        pagination={{
          bulletClass: classes.bullet,
          bulletActiveClass: classes.bulletActive,
          clickable: true,
        }}
        keyboard={{ enabled: true }}
        loop
        onSwiper={(swiper) => setSwiperReady(true)}
        onActiveIndexChange={(swiper) => {
          const index = swiper.activeIndex % data.length;
          setActiveIndex(index);
          onSlideChange?.(index);
        }}
        slideToClickedSlide={true}
        shortSwipes={false}
        {...swiperProps}
      >
        {data.map((slide, index) => {
          return (
            <SwiperSlide key={slide.id} className={classes.slide}>
              <Slide {...slide} swiperReady={swiperReady} active={index == activeIndex} onClose={onClose} />
            </SwiperSlide>
          );
        })}
      </Swiper>
      <Arrow type="left" aria-label="previous slide" className={clsx(classes.navigation, classes.navigationPrev)} />
      <Arrow type="right" aria-label="next slide" className={clsx(classes.navigation, classes.navigationNext)} />
    </div>
  );
}
