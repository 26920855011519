import { makeStyles } from '@material-ui/styles';

export const videoWidth = 73;
export const videoHeight = (videoWidth * 9) / 16;

export const useStyles = makeStyles(
  (theme) => {
    return {
      root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
        width: '100%',
        height: '100%',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        pointerEvents: 'none',
      },
      background: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        background: theme.palette.background.semitransparent,
      },
      videoContainer: {
        position: 'relative',
        width: `${videoWidth}vw`,
        height: `${videoHeight}vw`,
      },
      video: {
        height: '100%',
        width: '100%',
        pointerEvents: 'auto',
      },
      close: {
        position: 'absolute',
        top: '1rem',
        left: '1rem',
        width: '2rem',
        pointerEvents: 'auto',
      },
    };
  },
  { name: 'Video' },
);
