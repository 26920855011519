import { gql } from '@apollo/client';
import {
  CategoryFragment,
  GeneralButtonFragment,
  ProductFragment,
  ProductPopupFragment,
  UploadFileFragment,
} from './fragments';

export const GET_HOME = gql`
  ${GeneralButtonFragment}
  ${UploadFileFragment}

  query Home {
    home {
      id
      created_at
      updated_at
      welcome {
        ... on ComponentSectionsWelcome {
          id
          type
          video
          image {
            ...UploadFileFragment
          }
        }
      }
      booth {
        ... on ComponentSectionsBooth {
          id
          reception {
            ... on ComponentSectionsReception {
              id
              image {
                ...UploadFileFragment
              }
              desktopFront {
                ...UploadFileFragment
              }
            }
          }
          agenda {
            ... on ComponentSectionsAgenda {
              id
              image {
                ...UploadFileFragment
              }
              button {
                ...GeneralButtonFragment
              }
            }
          }
          team {
            id
            image {
              ...UploadFileFragment
            }
            button {
              ...GeneralButtonFragment
            }
          }
          displays {
            id
            displayLeftFront {
              ...UploadFileFragment
            }
            displayLeftBack {
              ...UploadFileFragment
            }
            displayRightFront {
              ...UploadFileFragment
            }
            displayRightBack {
              ...UploadFileFragment
            }

            displayLeftFrontCategory {
              id
            }
            displayLeftBackCategory {
              id
            }
            displayRightBackCategory {
              id
            }
            displayRightFrontCategory {
              id
            }
          }
          general {
            topDisplay {
              ...UploadFileFragment
            }
            frontDisplay {
              ...UploadFileFragment
            }
          }
        }
      }
    }
  }
`;
