import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(
  (theme) => {
    return {
      root: {
        '--height': 'calc(100vh - 1.4375rem)',
        '--header-border-bottom-height': '1rem',
        '--header-height': 'calc(4.1875rem + var(--header-border-bottom-height))',

        position: 'absolute',
        maxWidth: '90vw',
        width: '76.25rem',
        height: 'var(--height)',
        overflow: 'hidden',
        bottom: 0,
        zIndex: 9999,
      },
      background: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        background: theme.palette.background.semitransparent,
      },
      header: {
        height: 'var(--header-height)',
        width: '100%',
        backgroundColor: '#fff',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: `var(--header-border-bottom-height) solid ${theme.palette.secondary.main}`,
      },
      logo: {
        height: '100%',
        // margin: '1rem 0.75rem',
        objectFit: 'contain',
      },
      close: {
        marginLeft: 'auto',
      },
      iframe: {
        width: '100%',
        height: 'calc(var(--height) - var(--header-height))',
        backgroundColor: '#fff',
      },
    };
  },
  { name: 'IframeContainer' },
);
