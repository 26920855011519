import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(
  (theme) => {
    return {
      root: {
        '--color': 'hsl(0, 0%, 0%)',
        width: '5.625rem',
        height: '3.9375rem',
        cursor: 'pointer',
        borderRadius: 'unset',
        backgroundColor: 'transparent',
        padding: 0,
        marginLeft: '1.875rem',
        marginTop: '2.125rem',
        position: 'fixed',
        zIndex: '999',
        top: 0,

        '&:hover': {
          backgroundColor: 'transparent',
          '--color': 'hsl(0, 0%, 100%)',
        },
      },
      background: {
        transition: theme.transitions.create(['fill']),
        fill: theme.palette.secondary.main,
        '&:hover': {
          fill: 'transparent',
        },
      },
      arrow: {
        transition: theme.transitions.create(['fill']),
        fill: 'var(--color)',
      },
    };
  },
  { name: 'ButtonBack' },
);
