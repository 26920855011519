/*
 * @author Lewy Blue Discoverthreejs.com
 *
 * ACESFilmic Tone mapping
 * Brightness
 * Contrast
 * Gamma correction
 *
 */

const ColorCorrectionShader = {
  uniforms: {
    tDiffuse: { value: null },
    exposure: { value: 1.0 },
    brightness: { value: 0 },
    contrast: { value: 0 },
  },

  vertexShader: /* glsl */ `
    varying vec2 vUv;

    void main() {

      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );

    }`,

  fragmentShader: /* glsl */ `
    // #define saturate(a) clamp( a, 0.0, 1.0 )

    uniform sampler2D tDiffuse;

    varying vec2 vUv;

    // uniform float exposure;

    // uniform float brightness;
    // uniform float contrast;


    // vec3 ACESFilmicToneMapping( vec3 color ) {

    //   color *= exposure;
    //   return saturate( ( color * ( 2.51 * color + 0.03 ) ) / ( color * ( 2.43 * color + 0.59 ) + 0.14 ) );

    // }

    // vec3 LinearToneMapping( vec3 color ) {

    //   return exposure * color;

    // }

    void main() {

      // gl_FragColor = texture2D( tDiffuse, vUv );

      vec4 tex = texture2D( tDiffuse, vec2( vUv.x, vUv.y ) );

      // gl_FragColor.rgb = ACESFilmicToneMapping( gl_FragColor.rgb );
      // gl_FragColor.rgb = LinearToneMapping( gl_FragColor.rgb );

      // gl_FragColor.rgb += brightness;

      // if (contrast > 0.0) {
      //   gl_FragColor.rgb = (gl_FragColor.rgb - 0.5) / (1.0 - contrast) + 0.5;
      // } else {
      //   gl_FragColor.rgb = (gl_FragColor.rgb - 0.5) * (1.0 + contrast) + 0.5;
      // }

      gl_FragColor = LinearTosRGB( tex );
      // gl_FragColor = LinearTosRGB( gl_FragColor );

    } `,
};

export { ColorCorrectionShader };
