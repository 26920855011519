import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(
  (theme) => {
    return {
      root: {
        width: '100%',
        height: '100%',
      },
      debugModel: {
        width: '400px',
        height: '300px',
      },
      blur: {
        filter: theme.blur,
        pointerEvents: 'none',
      },
    };
  },
  { name: 'Model3d' },
);
