import clsx from 'clsx';

import IconButton from '@material-ui/core/IconButton';
import { useStyles } from './ButtonBack.style';

export function ButtonBack({ className, ...props }) {
  const classes = useStyles();

  return (
    <IconButton aria-label="back" className={clsx(classes.root, className)} disableFocusRipple disableRipple {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 63">
        <path
          d="M23,0H67A23,23,0,0,1,90,23V40A23,23,0,0,1,67,63H23A23,23,0,0,1,0,40V23A23,23,0,0,1,23,0Z"
          className={classes.background}
        />
        <path
          className={classes.arrow}
          d="M12.919,1.038,1.475-10.906A1.741,1.741,0,0,1,1-12a1.588,1.588,0,0,1,.418-1.037L12.862-24.981a1.5,1.5,0,0,1,2.119-.045,1.492,1.492,0,0,1,.045,2.119L4.577-12l10.5,10.962a1.492,1.492,0,0,1-.045,2.119A1.489,1.489,0,0,1,12.919,1.038Z"
          transform="translate(35 44)"
        />
      </svg>
    </IconButton>
  );
}
