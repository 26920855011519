import {
  Color,
  HemisphereLight,
  DirectionalLight,
  Vector3, AmbientLight,
} from 'three';

const v = new Vector3();
const n = new Vector3();
const f = new Vector3();
function fitFrustumToBoundingBox(
  position,
  center,
  maxDim,
  shadowCamera,
) {
  v.subVectors(center, position);
  v.normalize().multiplyScalar(maxDim);

  n.subVectors(center, v);
  f.subVectors(center, v.multiplyScalar(-2));

  shadowCamera.near = n.distanceTo(position);
  shadowCamera.far = f.distanceTo(position);

  shadowCamera.updateProjectionMatrix();
}

function createAmbientLight() {
  const ambientLight = new AmbientLight(
    new Color(0xffffff).convertSRGBToLinear(),
    5,
  );

  ambientLight.name = 'ambient light';
  return ambientLight;
}

function createMainLight(camera, shadowBoundingBox) {
  const center = shadowBoundingBox.getCenter(new Vector3());
  const size = shadowBoundingBox.getSize(new Vector3());
  const maxDim = Math.max(size.x, size.y, size.z);

  const mainLight = new DirectionalLight(
    new Color(0xffffff).convertSRGBToLinear(),
    1,
  );

  mainLight.name = 'main light';
  mainLight.castShadow = true;

  mainLight.shadow.mapSize.width = 4096;
  mainLight.shadow.mapSize.height = 4096;

  mainLight.shadow.bias = -0.001;
  mainLight.shadow.radius = 4;

  mainLight.shadow.camera.top = size.y;
  mainLight.shadow.camera.bottom = -size.y;
  mainLight.shadow.camera.left = -size.x;
  mainLight.shadow.camera.right = size.x;

  mainLight.update = () => {
    mainLight.position.copy(camera.position);
    mainLight.position.add(new Vector3(2,5,2));
    fitFrustumToBoundingBox(
      camera.position,
      center,
      maxDim,
      mainLight.shadow.camera,
    );
  };

  return mainLight;
}

function createLights(camera, shadowBoundingBox) {
  return {
    ambient: createAmbientLight(),
    main: createMainLight(camera, shadowBoundingBox),
  };
}

export { createLights };
