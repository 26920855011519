import { useRef, useLayoutEffect } from 'react';
import clsx from 'clsx';
import ReactPlayer from 'react-player';
import { gsap } from 'gsap';

import { useStyles, videoWidth, videoHeight } from './Video.style';
import { ButtonClose } from 'src/components/ButtonClose';
import { useDataContext } from 'src/context/DataContext';
import { RECEPTION, OVERLAY, WELCOME } from 'src/constants/rooms';

export function Video({ className }) {
  const backgroundRef = useRef();
  const rootRef = useRef();
  const classes = useStyles();

  const { data, room, setRoom, setCategoryExit } = useDataContext();

  useLayoutEffect(() => {
    gsap.fromTo(backgroundRef.current, { opacity: 0 }, { opacity: 1 });
    gsap.fromTo(rootRef.current, { right: '-100%' }, { right: 0 });
  }, []);

  function onClose() {
    gsap.to(backgroundRef.current, { opacity: 0 });
    gsap.to(rootRef.current, {
      right: '-100%',
      onComplete: () => {
        if (room == RECEPTION) {
          setCategoryExit({ category: OVERLAY });
        } else {
          setRoom({ room: WELCOME });
        }
      },
    });
  }

  return (
    <>
      <div ref={backgroundRef} className={classes.background} onClick={onClose} />
      <div ref={rootRef} className={clsx(classes.root, className)}>
        <div className={classes.videoContainer}>
          <ReactPlayer
            url={data?.home?.welcome?.video}
            controls
            className={classes.video}
            width={`${videoWidth}vw`}
            height={`${videoHeight}vw`}
            config={{
              vimeo: {
                playerOptions: {
                  autoplay: true,
                },
              },
            }}
          />
          <ButtonClose className={classes.close} onClick={onClose} />
        </div>
      </div>
    </>
  );
}
