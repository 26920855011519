import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(
  (theme) => {
    return {
      root: {
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
        position: 'relative',
        backgroundColor: 'black',
      },
      model: {},
      bottomButton: {
        position: 'absolute',
        bottom: '1.7rem',
        zIndex: 999,
      },
      buttonChat: {
        right: '1.7rem',
      },
    };
  },
  { name: 'Booth' },
);
