import {AnimationClip, AnimationMixer, Box3, Group, Math} from 'three';
import { createMaterials } from './materials.js';

const itemScale = 0.2;
class Stacy extends Group {
  constructor(assets, name = "stacy") {
    super();

    this.name = name;
		this.updatables = [];

    this.setupModels(assets);
    this.addEventListeners();
    //this.scale.set(itemScale, itemScale, itemScale);
    //this.rotation.z = -Math.PI / 2;
    //this.rotation.y = Math.PI / 2;
		//this.rotation.x = -Math.PI / 2;aw
  }

  setupModels(assets) {
    const stacy = assets.models['stacy'];
    this.add(stacy.scene);
		const mixer = new AnimationMixer( stacy.scene );
		const clips = stacy.animations;
		this.updatables.push(mixer);
		const clip = AnimationClip.findByName( clips, 'idle' );
    clip.tracks.splice(3, 3);
    clip.tracks.splice(9, 3);

    const action = mixer.clipAction( clip );
		action.play();
		this.setupMaterials(assets);
    this.setupShadows();

    //this.scale.x = 0.0254;
    //this.scale.y = 0.0254;
    //this.scale.z = 0.0254;
  }

  setupMaterials(assets) {
    const materials = createMaterials(assets.textures);

		this.traverse((child) => {
      if (child.isMesh) {
        child.material = materials.stacyMaterial;
        child.castShadow = true;
        child.receiveShadow = true;
      }
      if (child.isBone && child.name === 'mixamorigNeck') {
        this.neck = child;
      }
      if (child.isBone && child.name === 'mixamorigSpine') {
        this.waist = child;
      }
		})
  }

  getBoundingBox() {
    if (!this.bbox) {
      this.bbox = new Box3().setFromObject(this);
    }
    return this.bbox;
  }

  setupShadows() {
    /*this.traverse(child => {
      child.castShadow = true;
      child.receiveShadow = false;
    });*/
  }

	update(delta) {
		this.updatables.forEach(updatable => updatable.update(delta));
	}

	addEventListeners() {
    document.addEventListener('mousemove', (e) => {
      let mousecoords = getMousePos(e);
      if (this.neck && this.waist) {
        moveJoint(mousecoords, this.neck, 50);
        moveJoint(mousecoords, this.waist, 30);
      }
    });

    function getMousePos(e) {
      return { x: e.clientX, y: e.clientY };
    }

    function moveJoint(mouse, joint, degreeLimit) {
      let degrees = getMouseDegrees(mouse.x, mouse.y, degreeLimit);
      joint.rotation.y = Math.degToRad(degrees.x);
      joint.rotation.x = Math.degToRad(degrees.y);
    }

    function getMouseDegrees(x, y, degreeLimit) {
      let dx = 0,
        dy = 0,
        xdiff,
        xPercentage,
        ydiff,
        yPercentage;

      let w = { x: window.innerWidth, y: window.innerHeight };

      // Left (Rotates neck left between 0 and -degreeLimit)

      // 1. If cursor is in the left half of screen
      if (x <= w.x / 2) {
        // 2. Get the difference between middle of screen and cursor position
        xdiff = w.x / 2 - x;
        // 3. Find the percentage of that difference (percentage toward edge of screen)
        xPercentage = (xdiff / (w.x / 2)) * 100;
        // 4. Convert that to a percentage of the maximum rotation we allow for the neck
        dx = ((degreeLimit * xPercentage) / 100) * -1;
      }
      // Right (Rotates neck right between 0 and degreeLimit)
      if (x >= w.x / 2) {
        xdiff = x - w.x / 2;
        xPercentage = (xdiff / (w.x / 2)) * 100;
        dx = (degreeLimit * xPercentage) / 100;
      }
      // Up (Rotates neck up between 0 and -degreeLimit)
      if (y <= w.y / 2) {
        ydiff = w.y / 2 - y;
        yPercentage = (ydiff / (w.y / 2)) * 100;
        // Note that I cut degreeLimit in half when she looks up
        dy = (((degreeLimit * 0.5) * yPercentage) / 100) * -1;
      }

      // Down (Rotates neck down between 0 and degreeLimit)
      if (y >= w.y / 2) {
        ydiff = y - w.y / 2;
        yPercentage = (ydiff / (w.y / 2)) * 100;
        dy = (degreeLimit * yPercentage) / 100;
      }
      return { x: dx, y: dy };
    }
  }
}

export { Stacy };
