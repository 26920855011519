import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(
  (theme) => {
    return {
      root: {
        zIndex: 99,
      },
    };
  },
  { name: 'ProductSlider' },
);
