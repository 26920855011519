import { useEffect } from 'react';
import clsx from 'clsx';

import IconButton from '@material-ui/core/IconButton';
import { useStyles } from './ButtonChat.style';
import { useDataContext } from 'src/context/DataContext';
import { dispatchOnChatbotOpen } from 'src/constants/event';
import './ButtonChat.style.css';

function loadChatbot() {
  (function (w, d, v3) {
    if (w.chaport) return;
    v3 = w.chaport = {};
    v3._q = [];
    v3._l = {};
    v3.q = function () {
      v3._q.push(arguments);
    };
    v3.on = function (e, fn) {
      if (!v3._l[e]) v3._l[e] = [];
      v3._l[e].push(fn);
    };
    var s = d.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = 'https://app.chaport.com/javascripts/insert.js';
    var ss = d.getElementsByTagName('script')[0];
    ss.parentNode.insertBefore(s, ss);
  })(window, document);
}

export function ButtonChat({ className, ...props }) {
  const classes = useStyles();
  const { data } = useDataContext();

  useEffect(() => {
    if (!data) return;

    window.chaportConfig = {
      appId: data.general?.chaportId,
      visitor: {
        custom: {
          eventid: '38f3f032-c301-ec11-94ef-000d3a339b44',
        },
      },
      analytics: data?.general?.GA_ID,
      // openIn: {
      //   selector: `#${chatId}`,
      // },
      launcher: {
        show: true,
      },
    };
    loadChatbot();
  }, [data]);

  return <div></div>;
}
