// Parse the cameras and targets from the loaded camera.glb file

function getCameraPositions(assets) {
  const cameras = assets.models.cameras.scene.children[0].children;

  const positions = {};

  for (const child of cameras) {
    let name = child.name.toLowerCase();

    if (name.indexOf('target') === -1) {
      if (positions[name] === undefined) positions[name] = {};

      positions[name].camera = child.position.clone();
    } else {
      name = name.replace('target', '');

      if (positions[name] === undefined) positions[name] = {};

      positions[name].target = child.position.clone();
    }
  }

  return positions;
}

export { getCameraPositions };
