import { useRef, useLayoutEffect, useEffect } from 'react';
import clsx from 'clsx';
import { gsap } from 'gsap';

import { ButtonClose } from '../ButtonClose';
import { useDataContext } from 'src/context/DataContext';
import { useStyles } from './IframeContainer.style';
import { OVERLAY } from 'src/constants/rooms';
import { dispatchOnChatbotClose } from 'src/constants/event';

const chatId = 'iframe-chat';

export function IframeContainer({ className, isProduct, data: propsData = null }) {
  const backgroundRef = useRef();
  const rootRef = useRef();
  const classes = useStyles();
  const { iframeData, setCategoryExit, data, setShowProductIframe } = useDataContext();

  useLayoutEffect(() => {
    gsap.fromTo(backgroundRef.current, { opacity: 0 }, { opacity: 1 });
    gsap.fromTo(rootRef.current, { right: '-100%' }, { right: 0 });
  }, []);

  function onClose() {
    gsap.to(backgroundRef.current, { opacity: 0 });
    gsap.to(rootRef.current, {
      right: '-100%',
      onComplete: () => {
        if (isProduct) {
          setShowProductIframe(false);
        } else {
          setCategoryExit({ category: OVERLAY });
        }
      },
    });
  }

  propsData = propsData || iframeData;

  return (
    <>
      <div ref={backgroundRef} className={classes.background} onClick={onClose} />
      <div ref={rootRef} className={classes.root}>
        <div className={classes.header}>
          {propsData?.iframe_header_image?.url && (
            <img
              src={`${process.env.REACT_APP_ENDPOINT}${propsData?.iframe_header_image?.url}`}
              className={classes.logo}
              alt={propsData?.iframe_header_image?.alternativeText || propsData?.iframe_header_image?.name}
            />
          )}
          <ButtonClose className={classes.close} onClick={onClose} />
        </div>
        {!isProduct && <iframe id={`iframe-${propsData?.id}`} src={propsData?.url} className={classes.iframe} />}
        {isProduct && (
          <object data={propsData?.url} type="application/pdf" className={classes.iframe}>
            <iframe
              id={`iframe-${propsData?.id}`}
              src={`https://docs.google.com/viewer?url=${propsData?.url}&embedded=true`}
              className={classes.iframe}
            ></iframe>
          </object>
        )}
      </div>
    </>
  );
}
