import { gsap } from 'gsap';
import { createTheme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import MuiCssBaseline, { fontFamily, fontFamilies } from 'src/styles/Mui/MuiCssBaseline';

const blue = (alpha = 1) => `hsla(196, 46%, 48%, ${alpha})`;

// Create a theme instance.
export const theme = createTheme({
  palette: {
    primary: {
      main: 'hsl(60, 0%, 100%)',
    },
    // orange
    secondary: {
      light: 'hsl(41deg, 100%, 66%)',
      main: 'hsl(41deg, 100%, 56%)',
      dark: 'hsl(41deg, 100%, 36%)',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
      blue: blue(),
      yellowgreen: '#AECF5F',
      semitransparent: 'hsl(0deg 0% 0% / 35%)',
    },
    text: {
      primary: 'hsl(60, 0%, 100%)',
      secondary: 'hsl(40deg, 75%, 45%)',
      tertiary: 'hsl(195deg, 3%, 75%)',
      disabled: 'hsl(0, 0%, 90%)',
    },
    action: {
      disabled: 'hsl(0, 0%, 10%)',
      disabledBackground: 'hsl(0, 0%, 90%)',
    },
  },
  components: {
    MuiCssBaseline: {
      ...MuiCssBaseline,
      styleOverrides: `
      ${MuiCssBaseline.styleOverrides}
      
      ::-webkit-scrollbar {
        margin-left: 1rem;
        width: .4rem;
        position: absolute;
      };

      ::-webkit-scrollbar-thumb {
        border-radius: 1em;
        background-color: ${blue(1)};
        transition: background-color 250ms;
      };

      ::-webkit-scrollbar-track {
        border-radius: 1em;
        background-color: ${blue(0.6)};
        transition: background-color 250ms;
      };

      `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&.MuiButtonBase-root': {
            minWidth: '4rem',
          },
        },
      },
    },
  },
  typography: {
    fontFamily,
    fontFamilies,
    fontWeightLighter: 100,
    fontWeightBolder: 900,
  },
  transitions: {
    duration: {
      standard: 250,
    },
  },
  shape: {
    borderRadius: '1.5px',
  },
  blur: 'blur(5px)',
});

theme.name = 'Base';

try {
  window.baseTheme = theme;
} catch {}

Object.entries(theme.transitions.duration).forEach(([key, value]) => {
  theme.transitions.duration[`${key}Sec`] = value / 1000;
});

gsap.defaults({ duration: theme.transitions.duration.standardSec });
