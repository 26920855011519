import {MeshPhongMaterial} from 'three';


function createStacyMaterial(texture) {
  texture.flipY = false;
  return new MeshPhongMaterial({
    map: texture,
    color: 0xffffff,
    skinning: true
  });
}

function createMaterials(textures) {
  return {
		stacyMaterial: createStacyMaterial(textures.stacy)
  };
}

export { createMaterials };
