import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(
  (theme) => {
    return {
      root: {
        '--default-color': '#424242',
        '--hover-color': '#ffffff',
        '--color': 'var(--default-color)',
        '--size': '2.5rem',

        cursor: 'pointer',
        width: 'var(--size)',
        height: 'var(--size)',
        color: 'var(--color)',
        transition: theme.transitions.create(['color']),

        '&:hover': {
          color: 'var(--hover-color)',
          backgroundColor: 'unset',
        },

        '& line': {
          fill: 'none',
          stroke: 'currentColor',
          strokeLinecap: 'round',
          strokeWidth: '2',
          transition: theme.transitions.create(['stroke']),
        },
      },
    };
  },
  { name: 'ButtonClose' },
);
