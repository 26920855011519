import { setupControls } from './setupControls.js';

class TargetCameraControls {
  constructor(engine, container) {
    this.controls = setupControls(engine.camera, container);
  }

  update(delta) {
    this.controls.update(delta);
  }

  target(positions) {
    const endPosition = positions.camera.clone();
    const endTarget = positions.target.clone();

    this.controls.lerpLookAt(endPosition, endTarget);
  }

  setInitialCameraPosition(cameraPositions) {
    // TODO: controls.controls :/
    this.controls.setLookAt(
      ...Object.values(cameraPositions.overview.camera),
      ...Object.values(cameraPositions.overview.target),
      false,
    );
  }
}

export { TargetCameraControls };
