export const ProductPopupFragment = `
  fragment ProductPoupFragment on ComponentProductPopup {
    title
    text
    headerImage {
      ...UploadFileFragment
    }
    leftImage {
      ...UploadFileFragment
    }
    button {
      ...GeneralButtonFragment
    }
    rightImage {
      ...UploadFileFragment
    }
  }
`;
