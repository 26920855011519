import { Clock, MeshBasicMaterial, Raycaster, Vector2, ArrowHelper } from 'three';
import {OVERLAY_LAYER, TOUCHABLE_LAYER} from "src/Parker/World";

const clock = new Clock();
const darkMaterial = new MeshBasicMaterial( { color: "black" } );
const materials = [];
const raycaster = new Raycaster();
const raycasterHover = new Raycaster();
const mouse = new Vector2();
const mouseHover = new Vector2();

let arrowHelper;

const addArrowHelper = (scene, raycaster) => {
  scene.remove(arrowHelper);
  arrowHelper = new ArrowHelper( raycaster.ray.direction, raycaster.ray.origin, 100, Math.random() * 0xffffff )
  scene.add(arrowHelper);
}
let now,delta,then = Date.now();
const interval = 1000/30;

class Core {
  update(updatables) {
    const delta = clock.getDelta();
    for (const object of updatables) {
      if (typeof object.update === 'function') {
        object.update(delta);
      }
    }
  }
  
  render(renderer, scene, camera) {
    if(!isNaN(mouse.x)) {
      raycaster.setFromCamera( mouse, camera );
      const intersects = raycaster.intersectObjects( scene.children, true);
      if(intersects.length > 0) {
        for ( let i = 0; i < intersects.length; i ++ ) {
          intersects[i].object?.onClick();
          break;
        }
      }
      mouse.x = NaN;
    }
    // CHECK MOVE
    raycasterHover.setFromCamera( mouseHover, camera );
    const intersects = raycasterHover.intersectObjects( scene.children, true);
    if(intersects.length > 0) {
      for ( let i = 0; i < intersects.length; i ++ ) {
        if(intersects[i].object?.onHover) {
          intersects[i].object?.onHover();
          break;
        }
      }
    }

    if(this.renderBloom) {
			scene.traverse( (obj) => darkenNonBloomed(obj) );
			this.bloomComposer.render();
			scene.traverse( (obj) => restoreMaterial(obj) );
			this.finalComposer.render();
		} else {
			renderer.render(scene, camera);
		}
  }

  start(renderer, scene, camera, updatables) {
    raycaster.layers.set(TOUCHABLE_LAYER);
    raycasterHover.layers.set(OVERLAY_LAYER);
    renderer.setAnimationLoop(() => {
      now = Date.now();
      delta = now - then;
      if (delta > interval) {
        this.update(updatables);
        this.render(renderer, scene, camera);
        then = now - (delta % interval);
      }
    });

    window.addEventListener( 'mousemove', onMouseMove, false );
    window.addEventListener( 'click', onMouseClick, false );
  }

  stop(renderer) {
    renderer.setAnimationLoop(null);
  }

  setupBloomEffectsRenderer(bloomComposer, finalComposer) {
		this.bloomComposer = bloomComposer;
		this.finalComposer = finalComposer;
  	this.renderBloom = true;
	}
}

function darkenNonBloomed( obj ) {
	if ( obj.isMesh && !obj.userData.bloom ) {
		materials[ obj.uuid ] = obj.material;
		obj.material = darkMaterial;
	}
}

function restoreMaterial( obj ) {
	if ( materials[ obj.uuid ] ) {
		obj.material = materials[ obj.uuid ];
		delete materials[ obj.uuid ];
	}
}

function onMouseMove( event ) {
  // calculate mouse position in normalized device coordinates
  // (-1 to +1) for both components
  mouseHover.x = ( event.clientX / window.innerWidth ) * 2 - 1;
  mouseHover.y = - ( event.clientY / window.innerHeight ) * 2 + 1;
}

function onMouseClick( event ) {
  // calculate mouse position in normalized device coordinates
  // (-1 to +1) for both components
  mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
  mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;
}

export { Core };
