import { gql } from '@apollo/client';

export const GET_GENERAL = gql`
  query General {
    general {
      GA_ID
      chaportId
      registerPopup {
        ... on ComponentGeneralRegisterPopup {
          Title
          text
          buttonText
          buttonUrl
        }
      }
    }
  }
`;
