import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(
  (theme) => {
    return {
      root: {
        '--color': 'hsl(0, 0%, 0%)',
        width: '5.125rem',
        height: '5.125rem',
        cursor: 'pointer',
        transition: theme.transitions.create(['color', 'background-color']),
        backgroundColor: theme.palette.secondary.main,

        '&:hover': {
          backgroundColor: theme.palette.secondary.main,
        },
      },
      icon: {
        fill: 'var(--color)',
        width: '2.375rem',
        height: 'auto',
      },
    };
  },
  { name: 'ButtonChat' },
);
