/**
 * @author alteredq / http://alteredqualia.com/
 */

import { ShaderMaterial, UniformsUtils } from 'three';
import { Pass } from 'three/examples/jsm/postprocessing/Pass.js';
import { ColorCorrectionShader } from './ColorCorrectionShader.js';

function ColorCorrectionPass(exposure, brightness, contrast) {
  Pass.call(this);

  if (ColorCorrectionPass === undefined)
    console.error(
      'ColorCorrectionPass relies on ColorCorrectionShader',
    );

  const shader = ColorCorrectionShader;

  this.uniforms = UniformsUtils.clone(shader.uniforms);

  if (exposure !== undefined) this.uniforms.exposure.value = exposure;
  if (brightness !== undefined)
    this.uniforms.brightness.value = brightness;
  if (contrast !== undefined) this.uniforms.contrast.value = contrast;

  this.material = new ShaderMaterial({
    uniforms: this.uniforms,
    vertexShader: shader.vertexShader,
    fragmentShader: shader.fragmentShader,
  });

  this.fsQuad = new Pass.FullScreenQuad(this.material);
}

ColorCorrectionPass.prototype = Object.assign(
  Object.create(Pass.prototype),
  {
    constructor: ColorCorrectionPass,

    render(renderer, writeBuffer, readBuffer) {
      this.uniforms.tDiffuse.value = readBuffer.texture;

      if (this.renderToScreen) {
        renderer.setRenderTarget(null);
        this.fsQuad.render(renderer);
      } else {
        renderer.setRenderTarget(writeBuffer);
        if (this.clear) renderer.clear();
        this.fsQuad.render(renderer);
      }
    },
  },
);

export { ColorCorrectionPass };
