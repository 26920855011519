import clsx from 'clsx';
import { useEffect } from 'react';

import { dispatchOnDisplaysChange, dispatchOnDisplaysActivate, dispatchOnChatbotClose } from 'src/constants/event';
import { DISPLAYS, TEAM, AGENDA, RECEPTION, WELCOME, OVERLAY, REGISTER, VIDEO } from 'src/constants/rooms';
import { useDataContext } from 'src/context/DataContext';
import { CategoriesSlider } from '../CategoriesSlider/CategoriesSlider';
import { Menu } from '../Menu';
import { Model3d } from '../Model3d';
import { ButtonChat } from '../ButtonChat';
import { useStyles } from './Booth.style';
import { ButtonBack } from '../ButtonBack';
import { IframeContainer } from '../IframeContainer';
import { RegisterModal } from '../RegisterModal';
import { Video } from '../Video';

export function Booth({ className, type = 'left', ...props }) {
  const classes = useStyles();
  const { room, category, categories, product, setRoom, showProductIframe, setShowProductIframe } = useDataContext();
  useEffect(() => console.clear(), []);

  const roomsWithIframes = [AGENDA, TEAM];
  return (
    <div className={classes.root}>
      {process.env.REACT_APP_HIDE_MODEL == '1' && <Model3d className={classes.model} />}
      {room == DISPLAYS && category && product && <CategoriesSlider />}
      {roomsWithIframes.includes(room) && category == OVERLAY && <IframeContainer />}
      {showProductIframe && <IframeContainer isProduct />}

      {(roomsWithIframes.includes(room) || room == RECEPTION) && (
        <ButtonBack
          onClick={() => {
            if (showProductIframe) {
              setShowProductIframe(false);
            } else {
              setRoom({ room: WELCOME });
            }
          }}
        />
      )}

      <Menu className={classes.bottomButton} />
      <ButtonChat className={clsx(classes.bottomButton, classes.buttonChat)} />

      {room == REGISTER && <RegisterModal />}
      {(room == VIDEO || (room == RECEPTION && category == OVERLAY)) && <Video />}

      {process.env.REACT_APP_DEBUG_MODEL === '1' && (
        <div style={{ position: 'fixed', zIndex: 999, top: 0 }}>
          <button onClick={() => setRoom({ room: VIDEO })}>VIDEO</button>
          <button onClick={() => setRoom({ room: AGENDA })}>AGENDA</button>
          <button onClick={() => setRoom({ room: TEAM })}>TEAM</button>
          <button onClick={() => setRoom({ room: RECEPTION })}>RECEPTION</button>
          <button onClick={() => setRoom({ room: REGISTER })}>REGISTER</button>
          {categories?.map(({ id, name, slug }) => (
            <button
              key={id}
              onClick={() => {
                if (room != DISPLAYS || category != slug) {
                  dispatchOnDisplaysChange({ category: slug });
                } else {
                  dispatchOnDisplaysActivate();
                }
              }}
            >
              Open Category - {name}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}
