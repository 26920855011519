import { useState } from 'react';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { ButtonClose } from '../ButtonClose';
import { Logo } from '../Logo';

import { useStyles } from './Product.style';
import { useStyles as useLinkStyles } from 'src/components/Link';
import { useDataContext } from 'src/context/DataContext';
import { IframeContainer } from '../IframeContainer';

export function Product({ slug, headerImage, leftImage, rightImage, title, text, links = [], onClose }) {
  const classes = useStyles();
  const linkClasses = useLinkStyles();
  const { setShowProductIframe, setIframeData } = useDataContext();

  function onClick(link) {
    if (link.target != '_iframe') return;
    setIframeData(link);
    setShowProductIframe(true);
  }

  return (
    <div className={classes.root}>
      <div className={classes.topBar}>
        {headerImage && <img src={headerImage} className={classes.logo} />}
        <ButtonClose className={classes.close} onClick={() => onClose()} />
      </div>
      <div className={classes.container}>
        <div className={clsx(classes.column, classes.leftColumn)}>
          <img src={leftImage} alt={{}} className={clsx(classes.image)} />
        </div>

        <div className={clsx(classes.column, classes.rightColumn)}>
          {rightImage && <img src={rightImage} alt={{}} className={clsx(classes.image)} />}
          <div className={classes.gradientBackground} />
          <div className={classes.content}>
            <div className={classes.textContainer}>
              <Typography variant="h3" className={classes.title}>
                {title}
              </Typography>
              <Typography variant="body1" className={classes.text}>
                {text}
              </Typography>
            </div>

            <div className={classes.linkContainer}>
              {links.map(({ id, url, text, enabled, ...props }, index) => {
                return (
                  <Link
                    key={id.toString()}
                    className={linkClasses.link}
                    href={url}
                    disabled={!enabled}
                    onClick={() => onClick(links[index])}
                    {...props}
                  >
                    {text}
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
