// https://github.com/yomotsu/camera-controls
// NOTE: This file has been heavily modified and simplified compared to yomotsu's version

import {
    MOUSE,
    Spherical,
    Vector2,
    Vector3,
    Math as MathUtils,
} from 'three';

import EventDispatcher from './EventDispatcher.js';

let _v2;
let _v3A;
let _v3B;
let _v3C;
let _xColumn;
let _yColumn;
let _sphericalA;
let _sphericalB;
const EPSILON = 0.001;
const PI_2 = Math.PI * 2;
const STATE = {
    NONE: -1,
    ROTATE: 0,
    DOLLY: 1,
    TRUCK: 2,
    TOUCH_ROTATE: 3,
    TOUCH_DOLLY_TRUCK: 4,
    TOUCH_TRUCK: 5,
};

export default class CameraControls extends EventDispatcher {
    constructor(camera, domElement, options = {}) {
        super();

        if (!camera.isPerspectiveCamera) {
            console.error('PerspectiveCamera required! ');
            return;
        }

        _v2 = new Vector2();
        _v3A = new Vector3();
        _v3B = new Vector3();
        _v3C = new Vector3();
        _xColumn = new Vector3();
        _yColumn = new Vector3();
        _sphericalA = new Spherical();
        _sphericalB = new Spherical();

        this._camera = camera;
        this._state = STATE.NONE;
        this.enabled = true;

        // How far you can dolly in and out
        this.minDistance = 0;
        this.maxDistance = Infinity;

        this.minPolarAngle = 0; // radians
        this.maxPolarAngle = Math.PI; // radians
        this.minAzimuthAngle = -Infinity; // radians
        this.maxAzimuthAngle = Infinity; // radians

        this.dampingFactor = 0.05;
        this.draggingDampingFactor = 0.25;
        this.azimuthRotateSpeed = 1.0;
        this.polarRotateSpeed = 1.0;
        this.dollySpeed = 1.0;
        this.truckSpeed = 2.0;
        this.dollyToCursor = false;

        this._domElement = domElement;

        // the location of focus, where the object orbits around
        this._target = new Vector3();
        this._targetEnd = new Vector3();

        // rotation
        this._spherical = new Spherical().setFromVector3(
            this._camera.position,
        );
        this._sphericalEnd = this._spherical.clone();

        // reset
        this._target0 = this._target.clone();
        this._position0 = this._camera.position.clone();
        this._zoom0 = this._camera.zoom;

        this._dollyControlAmount = 0;
        this._dollyControlCoord = new Vector2();
        this._hasUpdated = true;
        this.update(0);

        if (!this._domElement || options.ignoreDOMEventListeners) {

            this._removeAllEventListeners = () => {
            };

        } else {

            const scope = this;
            const dragStart = new Vector2();
            const dollyStart = new Vector2();
            let elementRect;

            const extractClientCoordFromEvent = function extractClientCoordFromEvent(event, out) {

                out.set(0, 0);

                if (isTouchEvent(event)) {

                    for (let i = 0; i < event.touches.length; i++) {

                        out.x += event.touches[i].clientX;
                        out.y += event.touches[i].clientY;

                    }

                    out.x /= event.touches.length;
                    out.y /= event.touches.length;
                    return out;

                }

                out.set(event.clientX, event.clientY);
                return out;


            };

            const onMouseDown = function onMouseDown(event) {

                if (!scope.enabled) return;

                event.preventDefault();

                const prevState = scope._state;

                switch (event.button) {

                    case MOUSE.LEFT:

                        scope._state = STATE.ROTATE;
                        break;

                    case MOUSE.MIDDLE:

                        scope._state = STATE.DOLLY;
                        break;

                    case MOUSE.RIGHT:

                        scope._state = STATE.TRUCK;
                        break;

                }

                if (prevState !== scope._state) {

                    startDragging(event);

                }
            };

            const onTouchStart = function onTouchStart(event) {
                //debugger;
                if (!scope.enabled) return;

                event.preventDefault();

                const prevState = scope._state;

                switch (event.touches.length) {

                    case 1: // one-fingered touch: rotate

                        scope._state = STATE.TOUCH_ROTATE;
                        break;

                    case 2: // two-fingered touch: dolly

                        scope._state = STATE.TOUCH_DOLLY_TRUCK;
                        break;

                    case 3: // three-fingered touch: truck

                        scope._state = STATE.TOUCH_TRUCK;
                        break;

                }

                if (prevState !== scope._state) {

                    startDragging(event);

                }
            };

            const onMouseWheel = function onMouseWheel(event) {

                if (!scope.enabled) return;

                event.preventDefault();

                // Ref: https://github.com/cedricpinson/osgjs/blob/00e5a7e9d9206c06fdde0436e1d62ab7cb5ce853/sources/osgViewer/input/source/InputSourceMouse.js#L89-L103
                const mouseDeltaFactor = 120;
                const deltaYFactor = navigator.platform.indexOf('Mac') === 0 ? -1 : -3;

                let delta;

                if (event.wheelDelta !== undefined) {

                    delta = event.wheelDelta / mouseDeltaFactor;

                } else if (event.deltaMode === 1) {

                    delta = event.deltaY / deltaYFactor;

                } else {

                    delta = event.deltaY / (10 * deltaYFactor);

                }

                let x;
                let y;

                if (scope.dollyToCursor) {

                    elementRect = scope._domElement.getBoundingClientRect();
                    x = (event.clientX - elementRect.left) / elementRect.width * 2 - 1;
                    y = (event.clientY - elementRect.top) / elementRect.height * -2 + 1;

                }

                dollyInternal(-delta, x, y);

            };

            const onContextMenu = function onContextMenu(event) {

                if (!scope.enabled) return;

                event.preventDefault();

            };

            const startDragging = function startDragging(event) {

                if (!scope.enabled) return;

                event.preventDefault();

                extractClientCoordFromEvent(event, _v2);

                elementRect = scope._domElement.getBoundingClientRect();
                dragStart.copy(_v2);

                if (scope._state === STATE.TOUCH_DOLLY_TRUCK) {
                    // 2 finger pinch
                    const dx = _v2.x - event.touches[1].pageX;
                    const dy = _v2.y - event.touches[1].pageY;
                    const distance = Math.sqrt(dx * dx + dy * dy);

                    dollyStart.set(0, distance);

                    // center coords of 2 finger truck
                    const x = (event.touches[0].pageX + event.touches[1].pageX) * 0.5;
                    const y = (event.touches[0].pageY + event.touches[1].pageY) * 0.5;

                    dragStart.set(x, y);

                }

                document.addEventListener('mousemove', dragging, {passive: false});
                document.addEventListener('touchmove', dragging, {passive: false});
                document.addEventListener('mouseup', endDragging);
                document.addEventListener('touchend', endDragging);

                scope.dispatchEvent({
                    type: 'controlstart',
                    originalEvent: event,
                });
            };

            const dragging = function dragging(event) {

                if (!scope.enabled) return;

                event.preventDefault();

                extractClientCoordFromEvent(event, _v2);

                const deltaX = dragStart.x - _v2.x;
                const deltaY = dragStart.y - _v2.y;

                dragStart.copy(_v2);

                switch (scope._state) {

                    case STATE.ROTATE:
                    case STATE.TOUCH_ROTATE:
                        const theta = PI_2 * scope.azimuthRotateSpeed * deltaX / elementRect.width;
                        const phi = PI_2 * scope.polarRotateSpeed * deltaY / elementRect.height;
                        scope.rotate(theta, phi, true);
                        break;

                    case STATE.TOUCH_DOLLY_TRUCK:

                        const dx = _v2.x - event.touches[1].pageX;
                        const dy = _v2.y - event.touches[1].pageY;
                        const distance = Math.sqrt(dx * dx + dy * dy);
                        const dollyDelta = dollyStart.y - distance;

                        const touchDollyFactor = 8;

                        const dollyX = scope.dollyToCursor
                            ? ((dragStart.x - elementRect.left) /
                            elementRect.width) *
                            2 -
                            1
                            : 0;
                        const dollyY = scope.dollyToCursor
                            ? ((dragStart.y - elementRect.top) /
                            elementRect.height) *
                            -2 +
                            1
                            : 0;
                        dollyInternal(
                            dollyDelta / touchDollyFactor,
                            dollyX,
                            dollyY,
                        );

                        dollyStart.set(0, distance);
                        truckInternal(deltaX, deltaY);
                        break;

                    case STATE.TRUCK:
                    case STATE.TOUCH_TRUCK:

                        truckInternal(deltaX, deltaY);
                        break;

                }

                scope.dispatchEvent({
                    type: 'control',
                    originalEvent: event,
                });
            };

            const endDragging = function endDragging(event) {

                if (!scope.enabled) return;

                scope._state = STATE.NONE;

                document.removeEventListener('mousemove', dragging);
                document.removeEventListener('touchmove', dragging);
                document.removeEventListener('mouseup', endDragging);
                document.removeEventListener('touchend', endDragging);

                scope.dispatchEvent({
                    type: 'controlend',
                    originalEvent: event,
                });
            };

            const truckInternal = function truckInternal(deltaX, deltaY) {

                const offset = _v3A.copy(scope._camera.position).sub(scope._target);
                // half of the fov is center to top of screen
                const fovInRad = scope._camera.fov * MathUtils.DEG2RAD;
                const targetDistance = offset.length() * Math.tan((fovInRad / 2));
                const truckX = (scope.truckSpeed * deltaX * targetDistance / elementRect.height);
                const pedestalY = (scope.truckSpeed * deltaY * targetDistance / elementRect.height);

                scope.truck(truckX, pedestalY, true);

            };

            const dollyInternal = function dollyInternal(delta, x, y) {

                const dollyScale = Math.pow(0.95, -delta * scope.dollySpeed);

                const distance = scope._sphericalEnd.radius * dollyScale - scope._sphericalEnd.radius;

                scope.dolly(distance);

            };

            this.onMouseDown = onMouseDown;
            this.onTouchStart = onTouchStart;
            this.onMouseWheel = onMouseWheel;
            this.startDragging = startDragging;
            this.dragging = dragging;
            this.endDragging = endDragging;

            this._domElement.addEventListener('mousedown', onMouseDown);
            this._domElement.addEventListener('touchstart', onTouchStart);
            this._domElement.addEventListener('wheel', onMouseWheel);
            this._domElement.addEventListener('contextmenu', onContextMenu);

            this._removeAllEventListeners = () => {

                scope._domElement.removeEventListener('mousedown', onMouseDown);
                scope._domElement.removeEventListener('touchstart', onTouchStart);
                scope._domElement.removeEventListener('wheel', onMouseWheel);
                scope._domElement.removeEventListener('contextmenu', onContextMenu);
                document.removeEventListener('mousemove', dragging);
                document.removeEventListener('touchmove', dragging);
                document.removeEventListener('mouseup', endDragging);
                document.removeEventListener('touchend', endDragging);

            };
        }
    }

    // azimuthAngle in radian
    // polarAngle in radian
    rotate(azimuthAngle, polarAngle, enableTransition) {

        this.rotateTo(
            this._sphericalEnd.theta + azimuthAngle,
            this._sphericalEnd.phi + polarAngle,
            enableTransition,
        );

    }

    // azimuthAngle in radian
    // polarAngle in radian
    rotateTo(azimuthAngle, polarAngle, enableTransition) {

        const theta = Math.max(this.minAzimuthAngle, Math.min(this.maxAzimuthAngle, azimuthAngle));
        const phi = Math.max(this.minPolarAngle, Math.min(this.maxPolarAngle, polarAngle));

        this._sphericalEnd.theta = theta;
        this._sphericalEnd.phi = phi;
        this._sphericalEnd.makeSafe();

        if (!enableTransition) {

            this._spherical.theta = this._sphericalEnd.theta;
            this._spherical.phi = this._sphericalEnd.phi;

        }

        this._hasUpdated = true;

    }

    dolly(distance, enableTransition) {

        this.dollyTo(this._sphericalEnd.radius + distance, enableTransition);

    }

    dollyTo(distance, enableTransition) {

        this._sphericalEnd.radius = MathUtils.clamp(
            distance,
            this.minDistance,
            this.maxDistance,
        );

        if (!enableTransition) {

            this._spherical.radius = this._sphericalEnd.radius;

        }

        this._hasUpdated = true;

    }

    truck(x, y, enableTransition) {

        this._camera.updateMatrix();

        _xColumn.setFromMatrixColumn(this._camera.matrix, 0);
        _yColumn.setFromMatrixColumn(this._camera.matrix, 1);
        _xColumn.multiplyScalar(x);
        _yColumn.multiplyScalar(-y);

        const offset = _v3A.copy(_xColumn).add(_yColumn);

        this._targetEnd.add(offset);

        if (!enableTransition) {
            this._target.copy(this._targetEnd);
        }

        this._hasUpdated = true;
    }

    setLookAt(
        positionX,
        positionY,
        positionZ,
        targetX,
        targetY,
        targetZ,
        enableTransition,
    ) {
        const position = _v3A.set(positionX, positionY, positionZ);
        const target = _v3B.set(targetX, targetY, targetZ);

        this._targetEnd.copy(target);
        this._sphericalEnd.setFromVector3(position.sub(target));
        this._sanitizeSphericals();

        if (!enableTransition) {

            this._target.copy(this._targetEnd);
            this._spherical.copy(this._sphericalEnd);

        }

        this._hasUpdated = true;

    }

    lerpLookAt(endPosition, endTarget) {

        const positionA = _v3A.copy(this._camera.position);
        const targetA = _v3B.copy(this._target);

        _sphericalA.setFromVector3(positionA.sub(targetA));

        const targetB = _v3A.copy(endTarget);
        this._targetEnd.copy(targetA).lerp(targetB, 1); // tricky

        const positionB = _v3B.copy(endPosition);
        _sphericalB.setFromVector3(positionB.sub(targetB));

        const deltaTheta = _sphericalB.theta - _sphericalA.theta;
        const deltaPhi = _sphericalB.phi - _sphericalA.phi;
        const deltaRadius = _sphericalB.radius - _sphericalA.radius;

        this._sphericalEnd.set(
            _sphericalA.radius + deltaRadius,
            _sphericalA.phi + deltaPhi,
            _sphericalA.theta + deltaTheta,
        );

        this._sanitizeSphericals();

        this._hasUpdated = true;

    }

    update(delta) {

        const currentDampingFactor = this._state === STATE.NONE ? this.dampingFactor : this.draggingDampingFactor;
        const lerpRatio = 1.0 - Math.exp(-currentDampingFactor * delta / 0.016);

        const deltaTheta = this._sphericalEnd.theta - this._spherical.theta;
        const deltaPhi = this._sphericalEnd.phi - this._spherical.phi;
        const deltaRadius = this._sphericalEnd.radius - this._spherical.radius;
        const deltaTarget = _v3A.subVectors(this._targetEnd, this._target);

        if (
            Math.abs(deltaTheta) > EPSILON
            || Math.abs(deltaPhi) > EPSILON
            || Math.abs(deltaRadius) > EPSILON
            || Math.abs(deltaTarget.x) > EPSILON
            || Math.abs(deltaTarget.y) > EPSILON
            || Math.abs(deltaTarget.z) > EPSILON
        ) {

            this._spherical.set(
                this._spherical.radius + deltaRadius * lerpRatio,
                this._spherical.phi + deltaPhi * lerpRatio,
                this._spherical.theta + deltaTheta * lerpRatio,
            );

            this._target.add(deltaTarget.multiplyScalar(lerpRatio));

            this._hasUpdated = true;

        } else {

            this._spherical.copy(this._sphericalEnd);
            this._target.copy(this._targetEnd);

        }

        if (this._dollyControlAmount !== 0) {

            const direction = _v3A.copy(_v3A.setFromSpherical(this._sphericalEnd)).normalize().negate();
            const planeX = _v3B.copy(direction).cross(_v3C.set(0.0, 1.0, 0.0)).normalize();
            const planeY = _v3C.crossVectors(planeX, direction);
            const worldToScreen = this._sphericalEnd.radius * Math.tan(this._camera.fov * MathUtils.DEG2RAD * 0.5);
            const prevRadius = this._sphericalEnd.radius - this._dollyControlAmount;
            const lerpRatio = (prevRadius - this._sphericalEnd.radius) / this._sphericalEnd.radius;
            const cursor = _v3A.copy(this._targetEnd)
                .add(planeX.multiplyScalar(this._dollyControlCoord.x * worldToScreen * this._camera.aspect))
                .add(planeY.multiplyScalar(this._dollyControlCoord.y * worldToScreen));
            this._targetEnd.lerp(cursor, lerpRatio);
            this._target.copy(this._targetEnd);

            this._dollyControlAmount = 0;

        }

        this._spherical.makeSafe();
        this._camera.position.setFromSpherical(this._spherical).add(this._target);
        this._camera.lookAt(this._target);

        const updated = this._hasUpdated;
        this._hasUpdated = false;

        if (updated) this.dispatchEvent({type: 'update'});
        return updated;

    }

    dispose() {

        this._removeAllEventListeners();

    }

    _sanitizeSphericals() {

        this._sphericalEnd.theta = this._sphericalEnd.theta % (PI_2);
        this._spherical.theta += PI_2 * Math.round(
            (this._sphericalEnd.theta - this._spherical.theta) / (PI_2),
        );

    }

}

function isTouchEvent(event) {

    return event.type == 'touchstart' || event.type == 'touchmove' || event.type == 'touchend' || 'TouchEvent' in window && event instanceof TouchEvent;

}