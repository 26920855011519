export const UploadFileFragment = `
fragment UploadFileFragment on UploadFile {
    id
    name
    alternativeText
    caption
    width
    height
    formats
    url
  }
`;
