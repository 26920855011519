import { useLayoutEffect, useEffect, useState, useRef } from 'react';
import { gsap } from 'gsap';

import { useDataContext } from 'src/context/DataContext';
import { Product } from '../Product';
import { ModalContainer } from '../ModalContainer';
import { useStyles } from './ProductSlider.style';

export function ProductSlider({ data: _data, match }) {
  const rootRef = useRef();
  const classes = useStyles();
  const [data, setData] = useState(_data);
  const [exit, setExit] = useState(false);
  const { room, product, categories, category, setProduct, closeProductCb } = useDataContext();

  useEffect(() => {
    if (!categories) return;

    const selectedCategory = categories.find(({ slug }) => slug == category);
    const data = selectedCategory?.products?.map((product) => {
      return {
        ...product,
        headerImage:
          product.popup.headerImage?.url && `${process.env.REACT_APP_ENDPOINT}${product.popup.headerImage?.url}`,
        leftImage: `${process.env.REACT_APP_ENDPOINT}${product.popup.leftImage?.url}`,
        rightImage:
          product.popup.rightImage?.url && `${process.env.REACT_APP_ENDPOINT}${product.popup.rightImage?.url}`,
        title: product.popup?.title,
        text: product.popup?.text,
        links: product.popup?.button,
      };
    });

    setData(data);

    // debugger;
  }, [match, categories, category]);

  function onSlideChange(index) {
    setProduct({ category, product: data[index].slug });
  }

  useLayoutEffect(() => {
    if (!rootRef.current || rootRef.current.animated) return;

    rootRef.current.animated = true;
    gsap.fromTo(rootRef.current, { opacity: 0 }, { opacity: 1 });
  }, [data]);

  useEffect(() => {
    if (!exit) return;

    gsap.to(rootRef.current, { opacity: 0, onComplete: () => closeProductCb({ room, category, product }) });
  }, [exit, product]);

  if (!data) return null;
  const initialSlide = data.findIndex(({ slug }) => slug == product);

  return (
    <div ref={rootRef}>
      <ModalContainer
        Slide={Product}
        data={data}
        swiperProps={{
          spaceBetween: '10%',
          initialSlide,
        }}
        className={classes.root}
        onSlideChange={onSlideChange}
        onClose={() => setExit(true)}
      />
    </div>
  );
}
