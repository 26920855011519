export const WELCOME = 'welcome';
export const COMMUNITY = 'team';
export const TEAM = 'team';
export const AGENDA = 'agenda';
export const DISPLAYS = 'displays';
export const RECEPTION = 'reception';
export const REGISTER = 'register';
export const VIDEO = 'video';

export const DISPLAYS_LEFT_BACK = 'displaysLeftBack';
export const DISPLAYS_RIGHT_BACK = 'displaysRightBack';
export const DISPLAYS_LEFT_FRONT = 'displaysLeftFront';
export const DISPLAYS_RIGHT_FRONT = 'displaysRightFront';

export const OVERLAY = 'overlay';
