import {AnimationClip, AnimationMixer, Box3, Group} from 'three';
import { createMaterials } from './materials.js';

const itemScale = 0.0204;
class Spiderman extends Group {
  constructor(assets, name = "spiderman") {
    super();

    this.name = name;
		this.updatables = [];

    this.setupModels(assets);
    this.scale.set(itemScale, itemScale, itemScale);
    //this.rotation.z = -Math.PI / 2;
    //this.rotation.y = Math.PI / 2;
		//this.rotation.x = -Math.PI / 2;
  }

  setupModels(assets) {
    const spiderman = assets.models['spiderman'];

    this.add(spiderman.scene);
		const mixer = new AnimationMixer( spiderman.scene );
		const clips = spiderman.animations;
		this.updatables.push(mixer);
		const clip = AnimationClip.findByName( clips, 'mixamo.com' );
		const action = mixer.clipAction( clip );
		action.play();
		this.setupMaterials(assets);
    this.setupShadows();

    //this.scale.x = 0.0254;
    //this.scale.y = 0.0254;
    //this.scale.z = 0.0254;
  }

  setupMaterials(assets) {
    this.materials = createMaterials(assets);

		this.traverse((child) => {
			if (child.name === "shadow_1") {
				child.material = this.materials.shadow;
			} else if (child.name === "billboard_1") {
				child.material = this.materials.billboard;
			}
		})
  }

  getBoundingBox() {
    if (!this.bbox) {
      this.bbox = new Box3().setFromObject(this);
    }
    return this.bbox;
  }

  setupShadows() {
    /*this.traverse(child => {
      child.castShadow = true;
      child.receiveShadow = false;
    });*/
  }

	update(delta) {
		this.updatables.forEach(updatable => updatable.update(delta));
	}
}

export { Spiderman };
