import React, { useRef, useCallback, useEffect, useState } from 'react';

import {
  dispatchOnDataError,
  dispatchOnDataLoaded,
  dispatchOnDataLoading,
  ON_CATEGORY_CHANGE,
  ON_CATEGORY_CLOSE,
  ON_CLOSE_BUTTON,
  ON_PRODUCT_CHANGE,
  ON_PRODUCT_CLOSE,
  ON_ROOM_CHANGE,
  ON_ROOM_ENTER,
  ON_ROOM_ACTIVATE,
  dispatchOnRoomActivate,
  dispatchOnRoomExit,
  dispatchOnRoomEnter,
  dispatchOnCategoryChange,
  dispatchOnProductChange,
  dispatchOnProductClose,
  dispatchOnCategoryClose,
  ON_DISPLAYS_CHANGE,
  ON_DISPLAYS_ACTIVATE,
  dispatchOnDisplaysChange,
  dispatchOnDisplaysActivate,
  ON_CHATBOT_OPEN,
  ON_CHATBOT_CLOSE,
} from 'src/constants/event';
import { useApiData } from '../hooks/useApiData';
import { DISPLAYS, OVERLAY, TEAM, AGENDA, RECEPTION, WELCOME, REGISTER, VIDEO } from 'src/constants/rooms';

const useHashNavigation = process.env.REACT_APP_USE_HASH_HAVIGATION == 1;
const queryParams = window.location.search;
const initialStatus = (() => {
  const search = queryParams.substr(1).split('&');

  if (useHashNavigation) {
    const [room, category, product] = window.location.hash.slice(1)?.split?.('/');
    return { room, category, product };
  } else if (search.includes('loged')) {
    return { room: VIDEO, category: null, product: null };
  } else {
    return { room: REGISTER, category: null, product: null };
  }

  if (useHashNavigation) {
    pathname = window.location.hash;
  }
})();

const DataContext = React.createContext();

function getUrl({ room, category = '', product = '' }) {
  const aux = [room, category, product].filter((i) => !!i);
  return `/${aux.join('/')}${queryParams}`;
}

function DataProvider({ children }) {
  const { data, loading, error } = useApiData();
  const dataRef = useRef();

  const [room, setRoom] = useState(initialStatus.room);
  const [category, setCategory] = useState(initialStatus.category);
  const [product, setProduct] = useState(initialStatus.product);
  const [iframeData, setIframeData] = useState();
  const [showChatbot, setShowChatbot] = useState();
  const [showProductIframe, setShowProductIframe] = useState();

  useEffect(() => {
    const path = getUrl({ room, category, product });
    window.history.pushState(null, null, path);
  }, [room, category, product]);

  useEffect(() => (dataRef.current = data), [data]);

  useEffect(() => {
    if ((room, category != OVERLAY || !data)) return;

    switch (room) {
      case AGENDA:
        setIframeData(data.home.booth.agenda?.button);
        break;
      case RECEPTION:
        setIframeData(data.home.booth.reception?.button);
        break;
      case TEAM:
        setIframeData(data.home.booth.team?.button);
        break;
    }
  }, [room, category, data]);

  useEffect(() => {
    let pathname = window.location.pathname;
    if (useHashNavigation) {
      pathname = window.location.hash;
    }

    let [room, category, product] = pathname.slice(1)?.split?.('/');

    let previousRoom = room;
    // previousRoom = REGISTER;
    setRoom(previousRoom);
    setCategory(category);
    setProduct(product);

    /**
     * Room
     */
    function onRoomEnter({ detail: { id, room, canActivate } }) {
      const isSameRoom = previousRoom == room;
      if (!isSameRoom) dispatchOnRoomExit({ room: previousRoom });

      previousRoom = room;
      setRoom(room);
      if (room != DISPLAYS) setCategory(null);
      setProduct(null);

      if (isSameRoom && true) {
        if (
          (room == AGENDA && dataRef.current.home.booth.agenda?.button?.url) ||
          (room == RECEPTION && dataRef.current.home?.welcome?.video) ||
          (room == TEAM && dataRef.current.home.booth.team?.button?.url)
        ) {
          dispatchOnRoomActivate({ id, room });
        }
      }
    }

    function onRoomActivate({ detail: { room } }) {
      if ([TEAM, AGENDA, RECEPTION].includes(room)) {
        dispatchOnCategoryChange({ category: OVERLAY });
      }
    }

    /**
     * Category
     */
    function onCategoryChange({ detail: { category } }) {
      setCategory(category);
    }

    function onCategoryClose(event) {
      const { room } = event.detail;
      if (room != DISPLAYS) {
        setCategory(null);
      }

      setProduct(null);
    }

    /**
     * Product
     */
    function onProductChange({ detail: { category, product } }) {
      setCategory(category);
      setProduct(product);
    }

    function onProductClose(event) {
      const { room } = event.detail;
      if (room == DISPLAYS) {
        setProduct(OVERLAY);
      } else {
        setProduct(null);
      }
    }

    /**
     * Close
     */
    function onCloseButton() {}

    /**
     * Displays
     */
    function onDisplaysChange({ detail: { category } }) {
      setRoom(DISPLAYS);
      previousRoom = DISPLAYS;
      setCategory(category);
    }

    function onDisplaysActivate() {
      setProduct(OVERLAY);
      // dispatchOnProductChange({ product: OVERLAY });
    }

    /**
     * Chatbot
     */
    function onChatbotOpen() {
      setShowChatbot(true);
    }

    function onChatbotClose() {
      setShowChatbot(false);
    }

    const events = [
      // Room
      [ON_ROOM_CHANGE, onRoomEnter],
      [ON_ROOM_ENTER, onRoomEnter],
      [ON_ROOM_ACTIVATE, onRoomActivate],
      // Category
      [ON_CATEGORY_CHANGE, onCategoryChange],
      [ON_CATEGORY_CLOSE, onCategoryClose],
      // Product
      [ON_PRODUCT_CHANGE, onProductChange],
      [ON_PRODUCT_CLOSE, onProductClose],
      // Close
      [ON_CLOSE_BUTTON, onCloseButton],
      // Displays
      [ON_DISPLAYS_CHANGE, onDisplaysChange],
      [ON_DISPLAYS_ACTIVATE, onDisplaysActivate],
      // Chatbot
      [ON_CHATBOT_OPEN, onChatbotOpen],
      [ON_CHATBOT_CLOSE, onChatbotClose],
    ];

    events.forEach(([key, fn]) => document.addEventListener(key, fn));

    return () => {
      events.forEach(([key, fn]) => document.removeEventListener(key, fn));
    };
  }, []);

  useEffect(() => dispatchOnDataLoading({ loading }), [loading]);
  useEffect(() => {
    dispatchOnDataLoaded({ data });
  }, [data]);
  useEffect(() => dispatchOnDataError({ error }), [error]);

  const setRoomCb = useCallback(({ room, canActivate }) => dispatchOnRoomEnter({ room, canActivate }));
  const setCategoryCb = useCallback(({ category }) => dispatchOnCategoryChange({ category }));
  const setCategoryExitCb = useCallback(({ room, category }) => dispatchOnCategoryClose({ room, category }));
  const setProductCb = useCallback(({ category, product }) => dispatchOnProductChange({ category, product }));
  const closeProductCb = useCallback(({ room, category, product }) =>
    dispatchOnProductClose({ room, category, product }),
  );

  return (
    <DataContext.Provider
      value={{
        data,
        loading,
        error,
        room,
        setRoom: setRoomCb,
        setCategory: setCategoryCb,
        setCategoryExit: setCategoryExitCb,
        category,
        categories: data?.categories,
        setProduct: setProductCb,
        product,
        closeProductCb,
        iframeData,
        setIframeData,
        showChatbot,
        showProductIframe,
        setShowProductIframe,
      }}
    >
      {children}
    </DataContext.Provider>
  );
}

function useDataContext() {
  const context = React.useContext(DataContext);
  if (context === undefined) {
    throw new Error('useDataContext must be used within a DataProvider');
  }
  return context;
}

export { DataProvider, useDataContext };
