import { useLayoutEffect, useEffect, useState, useRef } from 'react';
import { gsap } from 'gsap';

import { useDataContext } from 'src/context/DataContext';
import { CategoryCard } from '../CategoryCard';
import { ModalContainer } from '../ModalContainer';
import { ProductSlider } from '../ProductSlider';
import { ButtonBack } from '../ButtonBack';
import { OVERLAY } from 'src/constants/rooms';

export function CategoriesSlider({ data: _data }) {
  const rootRef = useRef();
  const [data, setData] = useState(_data);
  const [exit, setExit] = useState(false);
  const { room, category, categories, setCategory, setCategoryExit, product, setShowProductIframe } = useDataContext();

  useEffect(() => {
    if (!categories) return;

    const data = Object.values(categories).map((category) => {
      return {
        ...category,
        image: `${process.env.REACT_APP_ENDPOINT}${category.display?.url}`,
        slides: category.featuredProducts.map((product) => {
          return {
            ...product,
            image: product?.thumbnail && `${process.env.REACT_APP_ENDPOINT}${product.thumbnail?.url}`,
            captionImage: product?.captionImage && `${process.env.REACT_APP_ENDPOINT}${product.captionImage?.url}`,
            title: product?.caption,
          };
        }),
      };
    });

    setData(data);
  }, [category, categories]);

  function onSlideChange(index) {
    setCategory({ category: data[index].slug });
  }

  useLayoutEffect(() => {
    if (!rootRef.current || rootRef.current.animated) return;

    rootRef.current.animated = true;
    gsap.fromTo(rootRef.current, { opacity: 0 }, { opacity: 1 });
  }, [data]);

  useEffect(() => {
    if (!exit) return;
    gsap.to(rootRef.current, {
      opacity: 0,
      onComplete: () => {
        setCategoryExit({ room, category });
        setShowProductIframe(false);
      },
    });
  }, [room, category, exit]);

  function onClose() {
    setExit(true);
  }

  if (!data) return null;

  const initialSlide = categories.findIndex(({ slug }) => slug == category);
  const showProducts = product && product != OVERLAY;

  return (
    <div ref={rootRef}>
      <ButtonBack onClick={onClose} />
      <ModalContainer
        Slide={CategoryCard}
        data={data}
        swiperProps={{
          centerInsufficientSlides: true,
          centeredSlides: true,
          loop: false,
          slidesPerView: 3,
          spaceBetween: '3%',
          initialSlide,
        }}
        onSlideChange={onSlideChange}
        showBackground={!product}
        blur={showProducts}
        onClose={onClose}
      />

      {showProducts && <ProductSlider />}
    </div>
  );
}
