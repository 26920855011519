import {
	Color,
	MeshStandardMaterial,
	DoubleSide,
	FrontSide,
	MeshPhongMaterial,
	ClampToEdgeWrapping,
	RepeatWrapping,
	MeshBasicMaterial, ShaderMaterial, sRGBEncoding
} from 'three';

import {OVERLAY_LAYER, TOUCHABLE_LAYER} from "src/Parker/World";


function createWallsMaterial() {
  // const material = new MeshBasicMaterial({
  const material = new MeshStandardMaterial({
    // const material = new MeshToonMaterial({
    color: new Color(0XADADAD).convertSRGBToLinear(),
    flatShading: true,
    transparent:false,
    //roughness: 0.5,
    side: DoubleSide,
  });

  material.name = 'walls';

  return material;
}

function createGlassMaterial() {
	const mat = new MeshPhongMaterial({
		transparent: true,
		color: 0xff6600,
		opacity: 0.7,
		emissive: 0x0,
		specular: 0x444444,
		shininess: 60,
		reflectivity: 1,
		refractionRatio: 1,
		depthTest: true,
		depthWrite: false
	})
	return mat;
}

function createBaseMaterial() {
	let mat = new MeshPhongMaterial({
		transparent: false,
		opacity: 1,
		color: 0xffffff,
		side: DoubleSide
	})
	return mat;
}

function createBillboardMaterial() {
	return createBaseMaterial();
}

function createLedMaterial() {
	let mat = new MeshPhongMaterial({
		transparent: true,
		color: 0xefa433,
		opacity: 0.9,
		emissive: 0x9db76d,
		specular: 0xd3d3d3,
		shininess: 100,
	})

	return mat;
}

function createMaterials() {
  return {
  	wall: createWallsMaterial(),
		glass: createGlassMaterial(),
		panel: createBillboardMaterial(),
		led: createLedMaterial(),
		overlay: createOverlayMaterials()
  };
}

function prepareTexture(texture, width, height) {
	if(texture) {
		texture.encoding = sRGBEncoding;
		texture.flipY = false;

		/*const aspectOfPlane = width / height;
		const aspectOfImage = texture.image.width / texture.image.height;
		let yScale = 1;
		let xScale = aspectOfPlane / aspectOfImage;
		if (xScale > 1) { // it doesn't cover so based on x instead
			xScale = 1;
			yScale = aspectOfImage / aspectOfPlane;
		}
		texture.repeat.set(xScale, yScale);
		texture.offset.set((1 - xScale) / 2, (1 - yScale) / 2);*/
	}
}

function createOverlayMaterials() {
	const textMaterial = new MeshBasicMaterial();
	textMaterial.opacity = 0;
	textMaterial.transparent = true;
	textMaterial.color = new Color(0x000000);
	return {
		overlayMaterial: createOverlayMaterial(),
		textMaterial
	}
}

function createOverlayBaseMaterial() {
	const baseMaterial = new MeshBasicMaterial();
	baseMaterial.opacity = 0;
	baseMaterial.transparent = true;
	return baseMaterial;
}

function createOverlayMaterial() {
	const overlayMaterial = new MeshBasicMaterial();
	overlayMaterial.transparent = true;
	overlayMaterial.opacity = 0;
	overlayMaterial.color = new Color(0xFB7100);
	overlayMaterial.depthTest = true;
	overlayMaterial.depthWrite = false;
	return overlayMaterial;
}

function createOverlayTextMaterial() {
	const textMaterial = new MeshPhongMaterial();
	textMaterial.opacity = 0;
	textMaterial.transparent = true;
	textMaterial.color = new Color(0xffffff);
	textMaterial.alphaTest = 0.01;
	return textMaterial
}

function createButtonBackgroundMaterial() {
	const buttonBackgroundMaterial = createOverlayMaterial();
	buttonBackgroundMaterial.color = new Color(0xff6600);
	//buttonBackgroundMaterial.opacity = 1;
	return buttonBackgroundMaterial;
}

function createButtonTextMaterial() {
	const textMaterial = createOverlayTextMaterial();
	textMaterial.color = new Color(0x000000);
	//textMaterial.opacity = 1;
	return textMaterial;
}

function setupOverlayMaterial(child) {
	if (child.name.includes('hl')) {
		child.material = createOverlayMaterial();
		child.layers.enable(TOUCHABLE_LAYER);
		child.layers.enable(OVERLAY_LAYER);
		child.onClick = () => {
			child.parent?.onClick();
		};
		child.onHover = () => {
			child.parent?.onHover();
		};
	} else if (child.name.includes('text')) {
		child.material = createOverlayTextMaterial();
	} else {
		child.material = createOverlayBaseMaterial();
		child.target = -1;
	}
}

function setupButtonMaterial(child) {
	if (child.name.includes('background')) {
		child.material = createButtonBackgroundMaterial();
		child.layers.enable(TOUCHABLE_LAYER);
		child.onClick = () => {
			child.parent?.onClick();
		};
	} else if(child.name.includes('texture')) {
		child.material = createButtonTextMaterial();
		child.layers.enable(TOUCHABLE_LAYER);
		child.onClick = () => {
			child.parent?.onClick();
		};
		//child.target = -1;
	} else if (child.name.includes('text')) {
		child.material = createButtonTextMaterial();
	} else {
		child.material = createOverlayBaseMaterial();
		child.target = -1;
	}
	if (child.name == 'poster_1') {
		child.layers.enable(TOUCHABLE_LAYER);
		child.onClick = () => {
			child.parent?.onClick();
		};
	}
}

function createShadowMaterial() {
	const mat = new MeshPhongMaterial({
		transparent: true,
	})
	return mat;
}

export { createMaterials,
	prepareTexture,
	createBillboardMaterial,
	createBaseMaterial,
	createOverlayBaseMaterial,
	createOverlayMaterial,
	createOverlayTextMaterial,
	setupOverlayMaterial,
	setupButtonMaterial,
	createShadowMaterial
};
