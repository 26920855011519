export const GeneralButtonFragment = `
  fragment GeneralButtonFragment on ComponentGeneralButton {
    id
    url
    text
    target
    enabled
    iframe_header_image {
      ... UploadFileFragment
    }
  }
`;
