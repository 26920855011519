import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(
  (theme) => {
    return {
      link: {
        cursor: 'pointer',
        textDecoration: 'none',
        background: theme.palette.secondary.main,
        color: '#000',
        padding: '0.75rem 2rem',
        transition: theme.transitions.create(['background', 'color']),
        fontFamily: theme.typography.fontFamilies.dinot,
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: '1.125rem',

        '&:hover': {
          background: '#000',
          color: '#fff',
        },
      },
    };
  },
  { name: 'Link' },
);
