export const ProductFragment = `
  fragment ProductFragment on Product {
    id
    name
    caption
    thumbnail {
      ...UploadFileFragment
    }
    captionImage {
      ...UploadFileFragment
    }
    popup {
      ...ProductPoupFragment
    }
  }
`;
