import { AssetManager } from './vendor/discoverthreejs/AssetManager/AssetManager.js';
import { Engine } from './vendor/discoverthreejs/Engine/Engine.js';
import { DebugOverlay } from './vendor/discoverthreejs/DebugOverlay/DebugOverlay.js';
import { TargetCameraControls } from './Camera/TargetCameraControls.js';

// import { PostEffects } from './Effects/PostEffects.js';
// import { Helpers } from './Renderables/Helpers/Helpers.js';
import { Highlights } from './Renderables/Highlights/Highlights.js';
import { createLights } from './Renderables/Lights/createLights.js';
import { getCameraPositions } from './Camera/utilities/getCameraPositions.js';
import { Booth } from "./Renderables/Booth/Booth";
import {PostEffects} from "./Effects/PostEffects";
import {Layers} from "three";
import {gsap} from "gsap";
import {
  ON_ROOM_ACTIVATE,
  ON_ROOM_CHANGE,
  ON_ROOM_ENTER,
  ON_ROOM_EXIT,
  dispatchOnCategoryChange,
  ON_CATEGORY_CHANGE,
  ON_CATEGORY_CLOSE,
  dispatchOnRoomChange,
  ON_DISPLAYS_CHANGE,
  dispatchOnDisplaysActivate,
  ON_DISPLAYS_ACTIVATE,
  dispatchOnDisplaysChange,
  dispatchOnRoomEnter
} from "src/constants/event";
import {OVERLAY} from "src/constants/rooms";

const ZOOM_STEP = window.ZOOM_STEP || 5;
const ENTIRE_SCENE = 0, BLOOM_SCENE = 1;

export const AGENDA = 'agenda';
export const COMMUNITY = 'team';
export const RECEPTION = 'reception';
export const DISPLAYS = 'displays';
export const WELCOME = 'welcome';

export const TOUCHABLE_LAYER = 99;
export const OVERLAY_LAYER = 98;

export const state = {
  [WELCOME]: {
    selected: true,
    item: null,
    camera: 'welcome',
  },
  [AGENDA]: {
    selected: false,
    item: null,
    camera: 'agenda',
  },
  [COMMUNITY]: {
    selected: false,
    item: null,
    camera: 'community',
  },
  [RECEPTION]: {
    selected: false,
    item: null,
    camera: 'reception',
  },
  [DISPLAYS]: {
    selected: false,
    item: null,
    items: {},
    camera: 'displays-left',
  },
};

export let isAnimating = false;

class World {
  constructor(container, debug = false) {
    this.debug = debug;
    this.container = container;
    this.engine = new Engine(container, debug);

    this.usingWebGL2 = this.engine.renderer.capabilities.isWebGL2;

    this.engine.setControls(
      new TargetCameraControls(this.engine, container),
    );

    this.assetManager = new AssetManager();
  }

  setCustomProperties(properties) {
    this.customProperties = properties;
  }

  async loadAssets(externalAssets = null) {
    this.assets = await this.assetManager.loadAssets(externalAssets);
    this.assets.cameraGroups = await (
      await fetch('./config/cameraGroups.json')
    ).json();
    this.assets.apartmentRelationships = await (
        await fetch('./config/apartmentRelationships.json')
    ).json();
    this.assets.config = await (
			await fetch('./config/config.json')
		).json();
    this.init();
  }

  init() {
    const cameraPositions = getCameraPositions(this.assets);
    this.engine.controls.setInitialCameraPosition(cameraPositions);
    this.createRenderables(cameraPositions);
    this.setupScene();
    this.setupEffects();
    if (this.debug) this.setupDebugOverlay();
    window.world = this;
    this.setupEvents();
  }

  setupEvents() {
    const disableAnimating = (extra) => {
      if(extra) {
        extra();
      }
      isAnimating = false;
    }
    document.addEventListener(ON_ROOM_ACTIVATE, (e) => {
      console.log("ROOM ACTIVATE - STOP RENDERING", e);
      this.engine?.stop();
    });
    document.addEventListener(ON_ROOM_CHANGE, (e) => {
      console.log("ROOM CHANGE", e);
    });
    document.addEventListener(ON_ROOM_ENTER, (e) => {
      isAnimating = true;
      gsap.delayedCall(1, disableAnimating);
      console.log("ROOM ENTER", e);

      Object.keys(state).forEach((key) => {
        state[key].selected = key === e.detail.room;
      }, this);

      this.onStateChange();
      this.engine?.start();
    });
    document.addEventListener(ON_ROOM_EXIT, (e) => {
      Object.keys(state).forEach((key) => {
        if(key === e.detail.room) {
          state[key].selected = false;
        }
      }, this);
    });

    document.addEventListener(ON_ROOM_CHANGE, (e) => {
      console.log("ROOM CHANGE", e);
    });
    document.addEventListener(ON_CATEGORY_CHANGE, (e) => {
      console.log("ON_CATEGORY_CHANGE", e);
      if(e.detail.category === OVERLAY) {
        gsap.delayedCall(0.1, () => {
          console.log("STOP")
          this.engine?.stop()});
      }
    });
    document.addEventListener(ON_CATEGORY_CLOSE, (e) => {
      console.log("ON_CATEGORY_CLOSE", e);
      isAnimating = true;
      const onCategoryCloseBack = () => {
        dispatchOnRoomEnter({room: WELCOME})
      }
      gsap.delayedCall(0.2, disableAnimating, [onCategoryCloseBack]);
      this.engine?.start();
    });
    document.addEventListener(ON_DISPLAYS_CHANGE, (e) => {
      console.log("ON_DISPLAYS_CHANGE", e);
      const disableAnimatingDisplays = () => {
        dispatchOnDisplaysChange({ category: e.detail.category });
      }
      if(!isAnimating) {
        gsap.delayedCall(2, disableAnimating, [disableAnimatingDisplays]);
      }
      if(state[DISPLAYS].items[e.detail.category].selected) {
        dispatchOnDisplaysActivate();
        return;
      }
      Object.keys(state).forEach((key) => {
        state[key].selected = false;
      }, this);
      state[DISPLAYS].selected = true;
      Object.keys(state[DISPLAYS].items).forEach(key => {
        state[DISPLAYS].items[key].selected = (key == e.detail.category);
      })
      isAnimating = true;
      this.onStateChange();
      //this.engine?.stop();
    });

    document.addEventListener(ON_DISPLAYS_ACTIVATE, (e) => {
      console.log("ON_CATEGORY_CHANGE", e);
      gsap.killTweensOf(disableAnimating);
      gsap.delayedCall(1, () => {this.engine?.stop()});
    });
  }

  createRenderables(cameraPositions) {
  	this.booth = new Booth(this.assets, this.customProperties);
    this.highlights = new Highlights(this.assets, cameraPositions);
    this.lights = createLights(
      this.engine.camera,
      this.booth.getBoundingBox(),
    );

    // this.helpers = new Helpers(this.lights);
  }

  setupEffects() {
		this.engine.setupBloomEffect();
		/*this.effects = new PostEffects(this.engine, this.booth.bloomedObjects);
		this.engine.addEffects(this.effects);
		this.engine.scene.background.convertSRGBToLinear();*/
  }

  setupScene() {
    //this.engine.environment.set(this.assets.environments.main, false);
    this.engine.environment.setBackground(0x000000);
    window.lights = this.lights;
    this.engine.addRenderable(this.lights.ambient);
    this.engine.addRenderable(this.lights.main, true);
    this.engine.addRenderable(this.booth, true);
    this.engine.addRenderable(this.highlights);

    this.engine.addRenderable(this.engine.camera);
  }

  start() {
    this.engine.start();
  }

  stop() {
    this.engine.stop();
  }

  showAllHighlights() {
    const target = this.highlights.showAllHighlights();
    this.engine.controls.target(target);
  }

  hideAllHighlights() {
    const target = this.highlights.hideAllHighlights();
    this.engine.controls.target(target);
  }

  showHighlights(names) {
    const target = this.highlights.showHighlights(names);
    this.engine.controls.target(target);
  }

  resetView() {
    this.showHighlights(["C"]);
    this.booth.resetState();
  }

  hideHighlights(names) {
    const target = this.highlights.hideHighlights(names);
    this.engine.controls.target(target);
  }

  getHighlightNames() {
    return this.highlights.getHighlightNames();
  }

  setupDebugOverlay() {
    const overlay = new DebugOverlay({
      world: this,
      camera: this.engine.camera,
      effects: this.effects,
      lights: this.lights,
      materials: [
        ...Object.values(this.mainBuilding.materials),
        ...Object.values(this.highlights.materials),
      ],
      renderer: this.engine.renderer,
      models: this.assets.models,
    });
  }

  zoomTo({ zoom, cb }) {
    this.engine.getControls().dollyTo(zoom, true);
    cb?.({ zoom });
  }

  zoomIn({ cb } = {}) {
    this.zoomTo({ zoom: this.engine.getControls()._spherical.radius - ZOOM_STEP, cb });
  }

  zoomOut({ cb } = {}) {
    this.zoomTo({ zoom: this.engine.getControls()._spherical.radius + ZOOM_STEP, cb });
  }

  onStateChange() {
    Object.keys(state).forEach((key) => {
      if(state[key].selected) {
        if(key === DISPLAYS) {
          const selectedItem = Object.keys(state[key].items).find((displayKey) => {
            return state[key].items[displayKey].selected;
          })
          this.showHighlights([state[key].items[selectedItem].camera]);
        } else {
          this.showHighlights([state[key].camera]);
        }
      }
    }, this);
    this.booth.onStateChange();
  }

  resetState() {
    Object.keys(state).forEach((key) => {
      state[key].selected = false;
    });
    this.onStateChange();
  }
}

export { World };
