import {
  Color,
  MeshBasicMaterial,
  MeshLambertMaterial,
  MeshPhongMaterial,
  MeshStandardMaterial,
  DoubleSide,
  FrontSide
} from 'three';

function createLabelMaterial() {
  const material = new MeshStandardMaterial({
    color: new Color(0xeeeeee).convertSRGBToLinear(),
    flatShading: true,
    side:FrontSide
  });

  material.name = 'labels';

  return material;
}

function createHighlightMaterial() {
  const material = new MeshStandardMaterial({
    color: new Color(0x355240).convertSRGBToLinear(),
    flatShading: true,
    emissive: 0x000000,
    transparent: true,
    opacity: 0.88,
    side:FrontSide
  });

  material.name = 'highlights';

  return material;
}

function createOutlineMaterial() {
  const material = new MeshStandardMaterial({
    color: new Color(0x355240).convertSRGBToLinear(),
    flatShading: true,
    emissive: 0x000000,
    transparent: true,
    opacity: 0.8,
    side:FrontSide
  });

  material.name = 'highlight outlines';

  return material;
}

function createMaterials() {
  return {
    label: createLabelMaterial(),
    outline: createOutlineMaterial(),
    highlight: createHighlightMaterial(),
  };
}

export { createMaterials };
