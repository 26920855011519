import {Color, MeshStandardMaterial, DoubleSide, FrontSide, MeshPhongMaterial} from 'three';

function createShadowMaterial(assets) {
	assets.textures.billboard_shadow.flipY = false;
	const mat = new MeshPhongMaterial({
		transparent: true,
		color: 0xff6600,
		opacity: 0.7,
		emissive: 0x0,
		specular: 0x444444,
		shininess: 60,
		reflectivity: 1,
		refractionRatio: 1,
		depthTest: true,
		depthWrite: false,
		map: assets.textures.billboard_shadow,
	})
	return mat;
}

function createBillboardMaterial() {
	const mat = new MeshPhongMaterial({
		transparent: false,
		opacity: 1,
	})
	return mat;
}

function createMaterials(assets) {
  return {
		shadow: createShadowMaterial(assets),
		billboard: createBillboardMaterial()
  };
}



export { createMaterials };
