import { useLayoutEffect, useRef, useEffect, useState } from 'react';
import clsx from 'clsx';

import { useStyles } from './Model3d.style';
import { World } from '../../Parker/World';
import { useDataContext } from 'src/context/DataContext';
import { DISPLAYS, OVERLAY, REGISTER, VIDEO } from 'src/constants/rooms';

const DEBUG = false;

function shouldShowBlur({ room, category, product, showChatbot }) {
  return (
    (room == DISPLAYS && category && product == OVERLAY) ||
    category == OVERLAY ||
    room == VIDEO ||
    room == REGISTER ||
    showChatbot
  );
}

function parseData(data) {
  return {
    assets: {
      booth_agenda: `${process.env.REACT_APP_ENDPOINT}${data.home.booth.agenda.image.url}`,
      booth_banner_top: `${process.env.REACT_APP_ENDPOINT}${data.home.booth.general.topDisplay.url}`,
      booth_community_wall: `${process.env.REACT_APP_ENDPOINT}${data.home.booth.team.image.url}`,
      booth_poster_1_front: `${process.env.REACT_APP_ENDPOINT}${data.home.booth.general.frontDisplay.url}`,
      booth_reception_desk: `${process.env.REACT_APP_ENDPOINT}${data.home.booth.reception.desktopFront.url}`,
      booth_wall_back: `${process.env.REACT_APP_ENDPOINT}${data.home.booth.reception.image.url}`,
      billboard_1: `${process.env.REACT_APP_ENDPOINT}${data.home.booth.displays.displayLeftFront.url}`,
      billboard_2: `${process.env.REACT_APP_ENDPOINT}${data.home.booth.displays.displayLeftBack.url}`,
      billboard_3: `${process.env.REACT_APP_ENDPOINT}${data.home.booth.displays.displayRightBack.url}`,
      billboard_4: `${process.env.REACT_APP_ENDPOINT}${data.home.booth.displays.displayRightFront.url}`,
    },
    properties: {
      billboard_1_category: `${
        data.categories.find((category) => {
          return category.id === data.home.booth.displays.displayLeftFrontCategory?.id;
        })?.slug
      }`,
      billboard_2_category: `${
        data.categories.find((category) => {
          return category.id === data.home.booth.displays.displayLeftBackCategory?.id;
        })?.slug
      }`,
      billboard_3_category: `${
        data.categories.find((category) => {
          return category.id === data.home.booth.displays.displayRightBackCategory?.id;
        })?.slug
      }`,
      billboard_4_category: `${
        data.categories.find((category) => {
          return category.id === data.home.booth.displays.displayRightFrontCategory?.id;
        })?.slug
      }`,
    },
  };
}

export function Model3d({ className }) {
  const classes = useStyles();
  const modelRef = useRef();
  const { loading, data, error, room, category, product, showChatbot } = useDataContext();
  const [showBlur, setShowBlur] = useState(shouldShowBlur({ room, category, product, showChatbot }));

  useLayoutEffect(() => {
    (async () => {
      const container = document.querySelector('#scene-container');

      const world = new World(container, DEBUG);
      modelRef.current = world;
    })();
  }, []);

  useEffect(() => {
    if (!modelRef.current || !data) return;
    const parsedData = parseData(data);
    (async () => {
      modelRef.current.setCustomProperties(parsedData.properties);
      await modelRef.current.loadAssets(parsedData.assets);
      modelRef.current.start();
    })();
  }, [data]);

  useEffect(() => {
    const auxBlur = shouldShowBlur({ room, category, product, showChatbot });
    if (auxBlur != showBlur) {
      setTimeout(() => setShowBlur(auxBlur), 300);
    }
  }, [showBlur, room, category, product, showChatbot]);

  return (
    <div
      id="scene-container"
      className={clsx(classes.root, className, {
        [classes.debugModel]: process.env.REACT_APP_DEBUG_MODEL === '1',
        [classes.blur]: showBlur,
      })}
    />
  );
}
