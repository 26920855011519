import { Box3, Group } from 'three';
import { createMaterials } from './materials.js';

import {AGENDA, COMMUNITY, DISPLAYS, RECEPTION, state} from '../../World.js'
import {gsap} from "gsap";
import {
  createOverlayBaseMaterial,
  setupOverlayMaterial
} from "src/Parker/Renderables/Booth/materials";
import {dispatchOnRoomEnter, dispatchOnCategoryChange} from "src/constants/event";

const itemScale = 0.0254;
class Billboard extends Group {
  constructor(assets, name = "billboard", targetCategory, targetCamera) {
    super();

    this.targetCamera = targetCamera;
    this.targetCategory = targetCategory;
    //this.targetCamera = name;
    this.name = name;

    this.setupModels(assets);
  }

  setupModels(assets) {
    const billboard = assets.models['billboard'].scene.children[0].clone();

    this.add(...billboard.children);
		this.setupMaterials(assets);
    this.setupShadows();

    //this.scale.x = 0.0254;
    //this.scale.y = 0.0254;
    //this.scale.z = 0.0254;
  }

  onItemHover = (item) => {
    const opacity = 0.6;
    this.overlayTarget.children.forEach((item) => {
      let targetOpacity = opacity;
      if(item.name.includes("text")) {
        targetOpacity = 1;
      }
      gsap.killTweensOf(item.material);
      gsap.to(item.material, { opacity: targetOpacity, duration: 0.4, onComplete: () => {
          gsap.to(item.material, { opacity: 0, duration: 0.2})
        }});
    });

  }

  setupMaterials(assets) {
    this.materials = createMaterials(assets);
		this.traverse((child) => {
			if (child.name === "shadow_1") {
				child.material = this.materials.shadow;
			} else if (child.name === "billboard_1") {
				child.material = this.materials.billboard;
			} else if (child.name === 'overlay') {
        this.overlayTarget = child;
        /*setupOverlayMaterial(child);
        if (child.target === -1) {
          child.target = DISPLAYS;
          child.onClick = () => {
            dispatchOnRoomEnter({room: child.target});
          };
          child.onHover = () => {
            this.onItemHover(child)
          };
        }*/
      }
		})
  }

  getBoundingBox() {
    if (!this.bbox) {
      this.bbox = new Box3().setFromObject(this);
    }
    return this.bbox;
  }

  setupShadows() {
    this.traverse(child => {
      child.castShadow = false;
      child.receiveShadow = false;
    });
  }

  onStateChange() {
    /*this.overlayTarget.children.forEach((item) => {
      let targetOpacity = 0.6;
      if(item.name.includes("text")) {
        targetOpacity = 1;
      }
      gsap.to(item.material, { opacity: state[DISPLAYS].selected ? targetOpacity : 0, duration: 1 });
    });*/
  }
}

export { Billboard };
