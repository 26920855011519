import clsx from 'clsx';

import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import { useStyles } from './RegisterModal.style';
import { useStyles as useLinkStyles } from 'src/components/Link';
import { useDataContext } from 'src/context/DataContext';

export function RegisterModal({}) {
  const classes = useStyles();
  const linkClasses = useLinkStyles();
  const { data } = useDataContext();

  if (!data) return null;

  const {
    title = 'Register for the Virtual Event',
    text = 'To access our virtual booth, please register and log in through our virtual event site.',
    buttonText = 'Visit Our Event Site',
    buttonUrl = '',
  } = data.general?.registerPopup || {};

  return (
    <div className={classes.root}>
      <div className={classes.background} />
      <div className={classes.content}>
        <Typography variant="h3" className={classes.title}>
          {title}
        </Typography>
        <Typography variant="body1" className={classes.text}>
          {text}
        </Typography>
        <Link className={linkClasses.link} href={buttonUrl} disabled={false}>
          {buttonText}
        </Link>
      </div>
    </div>
  );
}
