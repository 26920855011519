import { Group } from 'three';

import { createMaterials } from './materials.js';
import { cleanNames } from './utilities/cleanNames.js';

const itemScale = 0.0254;
class Highlights extends Group {
  constructor(assets, cameraPositions) {
    super();

    this.setupModels(assets);

    this.cameraGroups = assets.cameraGroups;
    this.cameraPositions = cameraPositions;
    this.aparmentRelationships = assets.apartmentRelationships;

    this.hideAllHighlights();
  }

  setupModels(assets) {
    const highlights = assets.models.highlights.scene.children[0];
    cleanNames(highlights);
    this.add(...highlights.children);
    this.setupMaterials();
    this.setupShadows();
    //this.setupFloorGroups();
  }

  setupMaterials() {
    this.materials = createMaterials();

    this.traverse(child => {
      if (child.name.includes('hl')) {}
    });
  }

  setupShadows() {
    this.traverse(child => {
      child.castShadow = false;
      child.receiveShadow = false;
    });
  }

  // Takes the array of highlight names, and returns that
  // camera that highlights that last name in the list
  getCameraTarget(highlight, isFloorTarget = false) {
    if (!highlight || !highlight.name) {
      if(isFloorTarget) {
        return this.cameraPositions.floors;
      } else{
        return this.cameraPositions.overview;
      }
    }

    let group = 'overview';

    for (const [key, entry] of Object.entries(this.cameraGroups)) {
      if (entry.includes(highlight.name)) {
        group = key;
      }
    }

    const initialPositions = this.cameraPositions[group];

    const positions = {
      camera: initialPositions.camera.clone(),
      target: initialPositions.target.clone(),
    }

    return positions;
  }

  getFloorsCameraTarget(highlight) {
    const target = this.getCameraTarget('', true);
    //target.camera.y = highlight.position.y;
    //target.target.y = highlight.position.y;
    return target;
  }

  getFinalName(name)
  {
    if (this.aparmentRelationships.hasOwnProperty(name)) {
      return this.aparmentRelationships[name];
    } else {
      return [name];
    }
  }

  showAllHighlights() {
    for (const node of this.children) {
      node.visible = true;
    }

    return this.getCameraTarget();
  }

  hideAllHighlights() {
    for (const node of this.children) {
      node.visible = false;
    }

    return this.getCameraTarget();
  }

  setHighlightsVisibility(names, enable = true) {

    //let finalNames = [];
    let highlight;
    for( const name of names ) {
      let finalName = this.getFinalName(name);
      finalName.forEach((apartment, index) => {
        highlight = this.getObjectByName(apartment);
        if (highlight) {
          highlight.visible = enable;
        } else {
          console.warn(
              `Attempting to highlight non-existent node: ${name}`,
          );
        }
        //finalNames.push(apartment);
      }, this);
    }

    return this.getCameraTarget(highlight);
  }

  showHighlights(names) {
    return this.setHighlightsVisibility(names, true);
  }

  hideHighlights(names) {
    return this.setHighlightsVisibility(names, false);
  }

  getHighlightNames() {
    return this.children.map(c => c.name);
  }
}

export { Highlights };
