import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import TagManager from 'react-gtm-module';

import { GET_HOME } from 'src/api/getHome';
import { GET_CATEGORIES } from 'src/api/getCategories';
import { GET_GENERAL } from 'src/api/getGeneral';

function slugify(value) {
  return value
    .toLocaleLowerCase()
    .split(' ')
    .filter((i) => !!i)
    .join('-');
}

function loadGa(gtmId) {
  TagManager.initialize({
    gtmId,
  });
}

export function useApiData() {
  const [data, setData] = useState();
  const { data: homeData, loading: homeLoading, error: homeError } = useQuery(GET_HOME);
  const { data: categoriesData, loading: categoriesLoading, categoriesError } = useQuery(GET_CATEGORIES);
  const { data: generalData, loading: generalLoading, generalError } = useQuery(GET_GENERAL);

  let error;
  if (homeError || categoriesError || generalError) {
    error = {
      homeError,
      categoriesError,
      generalError,
    };
  }

  useEffect(() => {
    let data;
    if ((!error, homeData && categoriesData && generalData)) {
      const categories = categoriesData.carousel.carouselCategory;

      data = {
        ...homeData,
        ...(generalData || {}),
        categories: Object.keys(categories)
          .sort()
          .filter((key) => key.startsWith('category') && categories[key])
          .map((key) => {
            const category = categories[key];
            const products = category.products.map((product) => {
              return {
                ...product,
                slug: slugify(product.caption),
              };
            });

            return {
              ...category,
              slug: slugify(category.name),
              products,
              featuredProducts: [
                products.find(({ id }) => category.featuredProducts?.featuredProduct1?.id == id),
                products.find(({ id }) => category.featuredProducts?.featuredProduct2?.id == id),
                products.find(({ id }) => category.featuredProducts?.featuredProduct3?.id == 'id'),
              ],
            };
          }),
      };

      loadGa(data.general.GA_ID);
    }

    setData(data);
  }, [error, homeData, categoriesData, generalData]);

  window.data = data;

  return {
    data,
    loading: !data && !error && (homeLoading || categoriesLoading || generalLoading),
    error,
  };
}
