// http://google-webfonts-helper.herokuapp.com/fonts/source-serif-pro/source-serif-pro?subsets=latin
const helveticaNeue = `
  @font-face {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    src: local("Helvetica Neue"),
        url("/fonts/HelveticaNeue/HelveticaNeueLTStd-Md.woff2") format("woff2"),
        url("/fonts/HelveticaNeue/HelveticaNeueLTStd-Md.woff") format("woff");
  }
`;

const dinot = `
  @font-face {
    font-family: DINOT, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    src: local(DINOT),
        url("/fonts/Dinot/DINOT-Medium.wof2") format("woff2"),
        url("/fonts/Dinot/DINOT-Medium.woff") format("woff");
  }

  @font-face {
    font-family: DINOT, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    src: local(DINOT),
        url("/fonts/Dinot/DINOT-Bold.woff2") format("woff2"),
        url("/fonts/Dinot/DINOT-Bold.woff") format("woff");
  }
`;

export default {
  styleOverrides: `
    ${helveticaNeue}
    ${dinot}
  `,
};

export const fontFamilies = {
  helveticaNeue: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  dinot: 'DINOT, Helvetica, Arial, sans-serif',
};

fontFamilies.serif = fontFamilies.helveticaNeue;
fontFamilies.sans = fontFamilies.helveticaNeue;

export const fontFamily = ['Helvetica Neue', 'DINOT', 'Helvetica', 'Aria', 'sans-serif'].join(',');
