import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { CacheProvider } from '@emotion/react';
import CssBaseline from '@material-ui/core/CssBaseline';
import createCache from '@emotion/cache';
import { theme } from 'src/theme';
import { ApolloProvider } from '@apollo/client';
import { createApolloClient } from './api/client';
import { DataProvider } from './context/DataContext';

import reportWebVitals from './reportWebVitals';
import { Booth } from './components/Booth';

import './styles.css';

export const cache = createCache({ key: 'css', prepend: true });
if (!window.emotionCache) {
  window.emotionCache = cache;
} else {
  debugger;
}
const client = createApolloClient({ cache: true });

ReactDOM.render(
  <React.StrictMode>
    <CacheProvider value={cache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ApolloProvider client={client}>
          <DataProvider>
            <Booth />
          </DataProvider>
        </ApolloProvider>
      </ThemeProvider>
    </CacheProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
