function switchBuildingDebug(folder, world) {
  let flat = false;

  const params = {
    switch: () => {
      if (!flat) {
        world.engine.scene.getObjectByName(
          '1515_alberni',
        ).visible = false;
        world.engine.scene.getObjectByName(
          '1515_alberni_flat',
        ).visible = true;
      } else {
        world.engine.scene.getObjectByName(
          '1515_alberni',
        ).visible = true;
        world.engine.scene.getObjectByName(
          '1515_alberni_flat',
        ).visible = false;
      }
      flat = !flat;
    },
  };
  folder.add(params, 'switch').name('Switch Model');
}

export { switchBuildingDebug };
