import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(
  (theme) => {
    return {
      root: {
        '--padding': '1.25rem',
        '--top-bar-height': '2.5rem',
        '--top-bar-margin-bottom': '0.5rem',

        width: 'calc( 100% - 2 * var(--padding) )',
        height: 'calc( 100% - 2 * var(--padding) )',
        overflow: 'hidden',
        position: 'relative',
        background: theme.palette.background.default,
        padding: 'var(--padding)',
      },
      // Structure
      topBar: {
        marginBottom: 'var(--top-bar-margin-bottom)',
        display: 'flex',
        justifyContent: 'space-between',
      },
      container: {
        height: 'calc( 100% - var(--top-bar-height) - var(--top-bar-margin-bottom) )',
        position: 'relative',
        display: 'flex',
      },
      leftColumn: {},
      rightColumn: {
        position: 'relative',
      },
      // TopBar
      logo: {
        height: 'var(--top-bar-height)',
        width: 'auto',
      },
      close: {
        marginLeft: 'auto',
        '&:hover': {
          color: '#000',
        },
      },
      // Container
      column: {
        width: '50%',
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
      },
      image: {
        objectFit: 'cover',
        width: '100%',
        height: '100%',
      },
      content: {
        '--vertical-spacing': '3rem',
        '--horizontal-spacing': '3rem',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'var(--vertical-spacing) var(--horizontal-spacing)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
      gradientBackground: {
        opacity: '0.812',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'linear-gradient(135deg, rgba(59,151,178,1) 0%, rgba(242,47,47,1) 100%)',
      },
      textContainer: {
        flexGrow: 2,
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '1.5rem',
        overflowY: 'auto',
      },
      title: {
        marginBottom: '1.5rem',
        fontFamily: theme.typography.fontFamilies.dinot,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: '2.5rem',
      },
      text: {
        fontFamily: theme.typography.fontFamilies.dinot,
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: '1.375rem',
      },
      linkContainer: {
        display: 'grid',
        gridAutoFlow: 'column dense',
        gridGap: '1.5rem',
      },
    };
  },
  { name: 'Product' },
);
