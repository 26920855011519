import { gql } from '@apollo/client';
import {
  CategoryFragment,
  GeneralButtonFragment,
  ProductFragment,
  ProductPopupFragment,
  UploadFileFragment,
} from './fragments';

export const GET_CATEGORIES = gql`
  ${CategoryFragment}
  ${GeneralButtonFragment}
  ${ProductFragment}
  ${ProductPopupFragment}
  ${UploadFileFragment}

  query Carousel {
    carousel {
      carouselCategory {
        category1 {
          ...CategoryFragment
        }
        category2 {
          ...CategoryFragment
        }
        category3 {
          ...CategoryFragment
        }
        category4 {
          ...CategoryFragment
        }
        category5 {
          ...CategoryFragment
        }
        category6 {
          ...CategoryFragment
        }
        category7 {
          ...CategoryFragment
        }
        category8 {
          ...CategoryFragment
        }
      }
    }
  }
`;
