import { useState, useRef, useEffect } from 'react';
import clsx from 'clsx';

import { useStyles } from './Menu.style';

import Button from '@material-ui/core/Button';

import { AGENDA, DISPLAYS, RECEPTION, WELCOME, TEAM } from 'src/constants/rooms';
import { useDataContext } from 'src/context/DataContext';
import { ButtonClose } from '../ButtonClose';

const hasButtonClose = process.env.REACT_APP_MENU_HAS_BUTTON_CLOSE === '1';

function MenuIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 22" {...props}>
      <g id="Hamburger" transform="translate(-266.5 -987.5)">
        <line
          x2="28"
          transform="translate(267.5 988.5)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="2"
        />
        <line
          x2="28"
          transform="translate(267.5 998.5)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="2"
        />
        <line
          x2="28"
          transform="translate(267.5 1008.5)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}

function HomeIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.996 29.748" {...props}>
      <g id="HOME" transform="translate(-126.504 -1001.377)" opacity="0.999">
        <path
          d="M16.8-14.532,1.338-27.149A2.484,2.484,0,0,0,0-27.623a2.484,2.484,0,0,0-1.341.473L-16.8-14.532a.6.6,0,0,0-.2.412.618.618,0,0,0,.119.335l.671.824a.6.6,0,0,0,.412.2.618.618,0,0,0,.332-.117L-12.75-15.1V1.037A1.063,1.063,0,0,0-11.688,2.1h8.5A1.063,1.063,0,0,0-2.125,1.037v-8.5l4.25.02V1.062A1.063,1.063,0,0,0,3.187,2.125l8.5-.022A1.063,1.063,0,0,0,12.75,1.041V-15.1l2.713,2.217a.618.618,0,0,0,.335.119.6.6,0,0,0,.412-.2l.671-.824A.618.618,0,0,0,17-14.118.6.6,0,0,0,16.8-14.532ZM10.618-.017h.007L4.249,0V-8.508A1.068,1.068,0,0,0,3.19-9.57l-6.375-.018h0A1.063,1.063,0,0,0-4.251-8.526V-.017h-6.374V-16.833L0-25.5l10.625,8.671Z"
          transform="translate(143.5 1029)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

function ReceptionIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
      <path
        d="M0-26a7,7,0,0,1,7,7,7,7,0,0,1-7,7,7,7,0,0,1-7-7A7,7,0,0,1,0-26ZM8-6a6.01,6.01,0,0,1,6,6V2H-14V0A6.01,6.01,0,0,1-8-6c5.312,0,4.206,1,8,1S2.681-6,8-6ZM0-28a9,9,0,0,0-9,9,9,9,0,0,0,9,9,9,9,0,0,0,9-9A9,9,0,0,0,0-28ZM8-8C2.225-8,3.562-7,0-7S-2.231-8-8-8a8,8,0,0,0-8,8V2a2,2,0,0,0,2,2H14a2,2,0,0,0,2-2V0A8,8,0,0,0,8-8Z"
        transform="translate(16 28)"
        fill="currentColor"
      />
    </svg>
  );
}

function AgendaIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.75 34" {...props}>
      <g transform="translate(-274.625 -998.25)">
        <path
          d="M11.687-25.5H8.5v-3.453a.8.8,0,0,0-.8-.8H7.172a.8.8,0,0,0-.8.8V-25.5H-6.375v-3.453a.8.8,0,0,0-.8-.8H-7.7a.8.8,0,0,0-.8.8V-25.5h-3.187a3.188,3.188,0,0,0-3.187,3.187V1.062A3.188,3.188,0,0,0-11.688,4.25H11.687a3.188,3.188,0,0,0,3.187-3.187V-22.312A3.188,3.188,0,0,0,11.687-25.5Zm-23.375,2.125H11.687a1.066,1.066,0,0,1,1.062,1.062v3.187h-25.5v-3.187A1.066,1.066,0,0,1-11.688-23.375Zm23.375,25.5H-11.688A1.066,1.066,0,0,1-12.75,1.062V-17h25.5V1.062A1.066,1.066,0,0,1,11.687,2.125ZM-5.047-8.5a.8.8,0,0,0,.8-.8v-2.656a.8.8,0,0,0-.8-.8H-7.7a.8.8,0,0,0-.8.8V-9.3a.8.8,0,0,0,.8.8Zm6.375,0a.8.8,0,0,0,.8-.8v-2.656a.8.8,0,0,0-.8-.8H-1.328a.8.8,0,0,0-.8.8V-9.3a.8.8,0,0,0,.8.8ZM7.7-8.5a.8.8,0,0,0,.8-.8v-2.656a.8.8,0,0,0-.8-.8H5.047a.8.8,0,0,0-.8.8V-9.3a.8.8,0,0,0,.8.8ZM1.328-2.125a.8.8,0,0,0,.8-.8V-5.578a.8.8,0,0,0-.8-.8H-1.328a.8.8,0,0,0-.8.8v2.656a.8.8,0,0,0,.8.8Zm-6.375,0a.8.8,0,0,0,.8-.8V-5.578a.8.8,0,0,0-.8-.8H-7.7a.8.8,0,0,0-.8.8v2.656a.8.8,0,0,0,.8.8Zm12.75,0a.8.8,0,0,0,.8-.8V-5.578a.8.8,0,0,0-.8-.8H5.047a.8.8,0,0,0-.8.8v2.656a.8.8,0,0,0,.8.8Z"
          transform="translate(289.5 1028)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

function DisplaysIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 35" {...props}>
      <g transform="translate(-345 -997)">
        <g transform="translate(42 7)">
          <g
            transform="translate(303 990)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          >
            <rect width="28" height="35" stroke="none" />
            <rect x="1" y="1" width="26" height="33" fill="none" />
          </g>
          <g
            transform="translate(303 1015)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          >
            <rect width="28" height="10" stroke="none" />
            <rect x="1" y="1" width="26" height="8" fill="none" />
          </g>
        </g>
      </g>
    </svg>
  );
}

function CommunityIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 31.5" {...props}>
      <path
        d="M15.75-15.75a5.623,5.623,0,0,0,5.625-5.625A5.623,5.623,0,0,0,15.75-27a5.623,5.623,0,0,0-5.625,5.625A5.623,5.623,0,0,0,15.75-15.75Zm0-9a3.376,3.376,0,0,1,3.375,3.375A3.376,3.376,0,0,1,15.75-18a3.376,3.376,0,0,1-3.375-3.375A3.376,3.376,0,0,1,15.75-24.75ZM0-13.5a7.871,7.871,0,0,0,7.875-7.875A7.871,7.871,0,0,0,0-29.25a7.871,7.871,0,0,0-7.875,7.875A7.871,7.871,0,0,0,0-13.5ZM0-27a5.632,5.632,0,0,1,5.625,5.625A5.632,5.632,0,0,1,0-15.75a5.632,5.632,0,0,1-5.625-5.625A5.632,5.632,0,0,1,0-27ZM17.156-13.5H14.344a5.086,5.086,0,0,0-2.89.907,9.529,9.529,0,0,1,1.786,1.575,2.8,2.8,0,0,1,1.1-.232h2.812A3.249,3.249,0,0,1,20.25-7.875,1.128,1.128,0,0,0,21.375-6.75,1.128,1.128,0,0,0,22.5-7.875,5.5,5.5,0,0,0,17.156-13.5ZM-15.75-15.75a5.623,5.623,0,0,0,5.625-5.625A5.623,5.623,0,0,0-15.75-27a5.623,5.623,0,0,0-5.625,5.625A5.623,5.623,0,0,0-15.75-15.75Zm0-9a3.376,3.376,0,0,1,3.375,3.375A3.376,3.376,0,0,1-15.75-18a3.376,3.376,0,0,1-3.375-3.375A3.376,3.376,0,0,1-15.75-24.75ZM5.632-12.094c-2.348,0-2.932.844-5.632.844s-3.284-.844-5.632-.844a7.749,7.749,0,0,0-6.49,3.3A8.057,8.057,0,0,0-13.5-4.275v3.15A3.376,3.376,0,0,0-10.125,2.25h20.25A3.376,3.376,0,0,0,13.5-1.125v-3.15A8.057,8.057,0,0,0,12.122-8.8,7.749,7.749,0,0,0,5.632-12.094ZM11.25-1.125A1.128,1.128,0,0,1,10.125,0h-20.25A1.128,1.128,0,0,1-11.25-1.125v-3.15a5.842,5.842,0,0,1,.991-3.263,5.5,5.5,0,0,1,4.62-2.306C-3.713-9.844-3.023-9,0-9s3.712-.844,5.632-.844a5.5,5.5,0,0,1,4.62,2.306,5.842,5.842,0,0,1,.991,3.263v3.15Zm-22.7-11.468a5.12,5.12,0,0,0-2.89-.907h-2.812A5.5,5.5,0,0,0-22.5-7.875,1.128,1.128,0,0,0-21.375-6.75,1.128,1.128,0,0,0-20.25-7.875a3.249,3.249,0,0,1,3.094-3.375h2.812a2.8,2.8,0,0,1,1.1.232A9.755,9.755,0,0,1-11.454-12.593Z"
        transform="translate(22.5 29.25)"
        fill="currentColor"
      />
    </svg>
  );
}

const buttons = [
  { Icon: HomeIcon, description: 'Home', target: WELCOME },
  { Icon: ReceptionIcon, description: 'Reception', target: RECEPTION },
  { Icon: AgendaIcon, description: 'Agenda', target: AGENDA },
  { Icon: DisplaysIcon, description: 'Displays', target: DISPLAYS },
  { Icon: CommunityIcon, description: 'Team', target: TEAM },
];

export function Menu({ className }) {
  const statusRef = useRef({});
  const classes = useStyles();
  const [isOpen, setOpen] = useState(false);
  const { room, setRoom } = useDataContext();

  function onClick(room) {
    // setOpen(false);
    setRoom({ room, canActivate: process.env.REACT_APP_DEBUG_MODEL === '1' });
  }

  function onMouseEnter() {
    clearTimeout(statusRef.current.timeout);
  }

  function onMouseLeave() {
    statusRef.current.timeout = setTimeout(() => {
      setOpen(false);
    }, 500);
  }

  return (
    <div
      id="menu"
      data-status="close"
      className={clsx(classes.root, className, {
        [classes.open]: isOpen,
        [classes.background]: isOpen && room != WELCOME,
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Button className={clsx(classes.button, classes.openButton)} onClick={() => setOpen(!isOpen)}>
        <MenuIcon className={classes.openIcon} />
      </Button>

      <div className={clsx(classes.options, { [classes.hasButtonClose]: hasButtonClose })}>
        {hasButtonClose && (
          <ButtonClose
            className={clsx(classes.button, classes.navButton, classes.closeButton)}
            classes={{ icon: classes.icon }}
            onClick={() => setOpen(false)}
          />
        )}
        {buttons.map(({ Icon, description, target }) => {
          return (
            <Button
              key={target}
              className={clsx(classes.button, classes.navButton, { [classes.selectedNavButton]: room == target })}
              onClick={() => onClick(target)}
            >
              <Icon className={classes.icon} />
              <span className={classes.buttonDescription}>{description}</span>
            </Button>
          );
        })}
      </div>
    </div>
  );
}
