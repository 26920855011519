import clsx from 'clsx';

import IconButton from '@material-ui/core/IconButton';
import { useStyles } from './Arrow.style';

function LeftArrow() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.806 119.438">
      <path d="M5570.977-22116.434l-49.395-61.016,49.395-55.6" transform="translate(-5520.582 22234.465)" />
    </svg>
  );
}

function RightArrow() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.806 119.438">
      <g transform="translate(50.806 118.025) rotate(180)">
        <path d="M5570.977-22116.434l-49.395-61.016,49.395-55.6" transform="translate(-5521.582 22233.053)" />
      </g>
    </svg>
  );
}

export function Arrow({ className, type = 'left', ...props }) {
  const classes = useStyles();

  return (
    <IconButton
      aria-label="previous slide"
      className={clsx(classes.root, className)}
      disableFocusRipple
      disableRipple
      {...props}
    >
      {type == 'left' && <LeftArrow />}
      {type == 'right' && <RightArrow />}
    </IconButton>
  );
}
