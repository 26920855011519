import clsx from 'clsx';

import { useStyles } from './ButtonClose.style';

import IconButton from '@material-ui/core/IconButton';

export function ButtonClose({ className, classes: propClasses = {}, ...props }) {
  const classes = useStyles();

  return (
    <IconButton
      aria-label="close"
      className={clsx(classes.root, className)}
      disableFocusRipple
      disableRipple
      {...props}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.506 20.506" className={propClasses.icon}>
        <g transform="translate(-1875.747 -53.247)">
          <line x2="25" transform="translate(1877.161 54.661) rotate(45)" stroke="currentColor" />
          <line x2="25" transform="translate(1894.839 54.661) rotate(135)" stroke="currentColor" />
        </g>
      </svg>
    </IconButton>
  );
}
